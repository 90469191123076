import React from 'react'
import './footer.scss'

const Footerline = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div className="loginfooter">
    <div className="theme-container">
      <div className="innerloginfooter">
      <img src="/logoblack.svg" alt="logoimg" className="logoimg" />
      <p className="footerpara">©{currentYear} GEMS. All rights reserved</p>
      </div>
    </div>
  </div>
  )
}

export default Footerline
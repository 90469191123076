import React, { useState, useEffect } from "react";
import "./datatable.scss";
import { Link } from "react-router-dom";
import { Accordion } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { CopyToClipboard } from "react-copy-to-clipboard";
import moment from "moment";
import jsonData from "./round.json";
import { CSVLink } from "react-csv";
import Loader from "../../../hooks/loader";
import { Api_Url } from "../../../utils/ApiUrl";
import { toast } from "react-toastify";
const Datatable = ({
  page,
  count,
  handlePageChange,
  pageCount,
  transactions,
  transactionApiCalled,
  setOrderDirection,
  setOrderField,
  orderDirection,
  orderField,
  dashboarddetail,
  usdPrice,
  tomiPrice,
  btcPrice,
  isLoading,
  tok,
}) => {
  const [copy, setCopy] = useState(null);
  const [copy2, setCopy2] = useState(null);
  const [claimsCSVData, setClaimsCSVData] = useState([]);
  const [claimsCSVDataHeaders, setClaimsCSVDataHeaders] = useState([]);
  const [data, setexceldata] = useState([]);
  const [roundData, setRoundData] = useState(null);
  const [round, setRound] = useState();

  const [leaderType, setLeaderType] = useState("");
  const [type, setType] = useState("");
  const [loading, setLoading] = useState("");
  const [showbtn, setShowBtn] = useState(true);
  const project = localStorage.getItem("project");

  const textCopiedFun2 = (index) => {
    setCopy2(index);

    setTimeout(() => {
      setCopy2(false);
    }, 1000);
  };
  const textCopiedFun = (index) => {
    setCopy(index);

    setTimeout(() => {
      setCopy(false);
    }, 1000);
  };
  function formatTokenValue(tokenValue) {
    if (tokenValue >= 1000000) {
      return (tokenValue / 1000000).toFixed(2) + "M";
    } else if (tokenValue >= 1000) {
      return (tokenValue / 1000).toFixed(2) + "k";
    } else {
      return tokenValue?.toFixed(2);
    }
  }
  function formatPriceValue(tokenValue) {
    if (tokenValue >= 1000000) {
      return (tokenValue / 1000000).toFixed(3) + "M";
    } else if (tokenValue >= 1000) {
      return (tokenValue / 1000).toFixed(2) + "k";
    } else {
      return tokenValue.toFixed(2);
    }
  }
  const toastErrorFunc = () => {
    toast.error("No data found to export");
  };
  const getAllClaimsData = async () => {
    console.log(project);
    setLoading(true);
    setShowBtn(false);

    var config = {
      responseType: "stream",
      url: `${Api_Url}/users/bulk-transaction-export?privateSale=${project}`,
      headers: {
        Authorization: "Bearer " + tok,
        "Content-Type": "application/json",
      },
    };
    let dynamicQuery = config.url;
    if (orderField) {
      dynamicQuery += `&orderField=${orderField}`;
    }
    if (orderDirection) {
      dynamicQuery += `&orderDirection=${orderDirection}`;
    }
    if (round) {
      dynamicQuery += `&round=${round}`;
    }
    if (leaderType) {
      dynamicQuery += `&leaderType=${leaderType}`;
    }
    if (type) {
      dynamicQuery += `&type=${type}`;
    }

    config.url = dynamicQuery;
    try {
      const response = await fetch(config.url, {
        method: "GET",
        headers: {
          Authorization: config.headers.Authorization,
          // Accept: "text/event-stream",
        },
      });
      if (response.status == 429) {
        toast.error("Limit exceeded. Please try again in one minutes.");
        setTimeout(() => {
          setLoading(false);
          setShowBtn(true);
        }, 3000);
      }
      const reader = response.body.getReader();

      let oldData = "";

      while (true) {
        const { done, value } = await reader.read();

        if (done) {
          break;
        }
        oldData += new TextDecoder().decode(value);
      }
      try {
        const parsedData = JSON.parse(oldData);

        const dataForCsv = parsedData?.map((item) => {
          let date = moment(item?.transactionTime).format("DD/MM/YYYY");
          let time = moment(item?.transactionTime).format("hh:mm A");
          let name = item?.user[0]?.name;
          let role =
            item?.user[0]?.role === "mini_agent"
              ? "Ambassador"
              : item?.user[0]?.role === "god_agent"
              ? "God Leader"
              : item?.user[0]?.role === "super_agent"
              ? "Super Leader"
              : item?.user[0]?.role === "agent"
              ? "Leader"
              : item?.user[0]?.role === "mega_agent"
              ? "Mega Leader"
              : "";
          let code = item?.code_code;
          let Hash = item?.transactionHash;
          let walletAddress = item?.by;
          let price = item?.price;
          let paid = item?.totalSaleConverted;
          let amount = item?.dopPurchased;
          let payment = item?.amountInvested;
          let type = item?.type;
          return {
            date: date,
            time: time,
            name: name,
            role: role,
            code: code,
            Hash: Hash,
            walletAddress: walletAddress,
            price: price,
            paid: paid,
            amount: amount,
            payment: payment,
            type: type,
          };
        });
        const headers = [
          { label: "date", key: "date" },
          { label: "time", key: "time" },
          { label: "name", key: "name" },
          { label: "role", key: "role" },
          { label: "code", key: "code" },
          { label: "Hash", key: "Hash" },
          { label: "walletAddress", key: "walletAddress" },
          { label: "price", key: "price" },
          { label: "paid", key: "paid" },
          { label: "amount", key: "amount" },
          { label: "payment", key: "payment" },
          { label: "type", key: "type" },
        ];
        setClaimsCSVData(dataForCsv);
        setClaimsCSVDataHeaders(headers);

        setexceldata(dataForCsv);
        setLoading(false);
      } catch (error) {
        console.error("Error parsing JSON data:", error);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <section className="datatable">
        {transactions?.length == 0 && (
          <div className="upperbtn">
            <button onClick={toastErrorFunc} className="mygreybtn mb-2">
              click to export
            </button>
          </div>
        )}
        {transactions?.length != 0 && (
          <div className="mb-3">
            <div className="upperbtn">
              {showbtn && (
                <button onClick={getAllClaimsData} className="mygreybtn">
                  click to export
                </button>
              )}
              {loading ? (
                <button className="mygreybtn">
                  <div className="text-center">
                    <div class="d-flex align-items-center justify-content-center">
                      <div class="spinner-border text-info" role="status">
                        <span class="sr-only"></span>
                      </div>
                    </div>
                  </div>
                </button>
              ) : (
                <>
                  {claimsCSVData && claimsCSVData.length > 0 && (
                    <CSVLink
                      data={claimsCSVData}
                      headers={claimsCSVDataHeaders}
                      filename={"Transactions_data.csv"}
                      className="mygreybtn"
                    >
                      Export CSV
                    </CSVLink>
                  )}
                </>
              )}
            </div>
          </div>
        )}
        <div className="bgtable">
          <div className="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th>
                    <div className="parent-tag">
                      Date{" "}
                      <div
                        className="filter-btn"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setOrderField("transactionTime");
                          setOrderDirection((prev) =>
                            prev == -1 ? 1 : prev == 1 ? -1 : -1
                          );
                        }}
                      >
                        <svg
                          width="9"
                          height="6"
                          viewBox="0 0 9 6"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7.90908 5.14258H4.09498H0.660442C0.072714 5.14258 -0.22115 4.36198 0.195157 3.90439L3.36644 0.418619C3.87458 -0.139912 4.70107 -0.139912 5.20921 0.418619L6.41528 1.74429L8.38049 3.90439C8.79068 4.36198 8.49681 5.14258 7.90908 5.14258Z"
                            fill={
                              orderField == "transactionTime" &&
                              orderDirection == -1
                                ? "black"
                                : "#DADADA"
                            }
                          />
                        </svg>
                        <svg
                          width="9"
                          height="6"
                          viewBox="0 0 9 6"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7.90908 0.857422H4.09498H0.660442C0.072714 0.857422 -0.22115 1.63802 0.195157 2.09561L3.36644 5.58138C3.87458 6.13991 4.70107 6.13991 5.20921 5.58138L6.41528 4.25571L8.38049 2.09561C8.79068 1.63802 8.49681 0.857422 7.90908 0.857422Z"
                            fill={
                              orderField == "transactionTime" &&
                              orderDirection == 1
                                ? "black"
                                : "#DADADA"
                            }
                          />
                        </svg>

                        {/* <img src={tableup} alt="img" className="img-fluid" />
                      <img src={tabledown} alt="img" className="img-fluid" /> */}
                      </div>
                    </div>
                  </th>
                  <th>
                    <div className="parent-tag">
                      name{" "}
                      <div
                        className="filter-btn"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setOrderField("name");
                          setOrderDirection((prev) =>
                            prev == -1 ? 1 : prev == 1 ? -1 : -1
                          );
                        }}
                      >
                        <svg
                          width="9"
                          height="6"
                          viewBox="0 0 9 6"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7.90908 5.14258H4.09498H0.660442C0.072714 5.14258 -0.22115 4.36198 0.195157 3.90439L3.36644 0.418619C3.87458 -0.139912 4.70107 -0.139912 5.20921 0.418619L6.41528 1.74429L8.38049 3.90439C8.79068 4.36198 8.49681 5.14258 7.90908 5.14258Z"
                            fill={
                              orderField == "name" && orderDirection == 1
                                ? "black"
                                : "#DADADA"
                            }
                          />
                        </svg>
                        <svg
                          width="9"
                          height="6"
                          viewBox="0 0 9 6"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7.90908 0.857422H4.09498H0.660442C0.072714 0.857422 -0.22115 1.63802 0.195157 2.09561L3.36644 5.58138C3.87458 6.13991 4.70107 6.13991 5.20921 5.58138L6.41528 4.25571L8.38049 2.09561C8.79068 1.63802 8.49681 0.857422 7.90908 0.857422Z"
                            fill={
                              orderField == "name" && orderDirection == -1
                                ? "black"
                                : "#DADADA"
                            }
                          />
                        </svg>

                        {/* <img src={tableup} alt="img" className="img-fluid" />
                      <img src={tabledown} alt="img" className="img-fluid" /> */}
                      </div>
                    </div>
                  </th>
                  <th>
                    <div className="parent-tag">
                      code{" "}
                      <div className="filter-btn">
                        <div
                          className="filter-btn"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setOrderField("code_code");
                            setOrderDirection((prev) =>
                              prev == -1 ? 1 : prev == 1 ? -1 : -1
                            );
                          }}
                        >
                          <svg
                            width="9"
                            height="6"
                            viewBox="0 0 9 6"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.90908 5.14258H4.09498H0.660442C0.072714 5.14258 -0.22115 4.36198 0.195157 3.90439L3.36644 0.418619C3.87458 -0.139912 4.70107 -0.139912 5.20921 0.418619L6.41528 1.74429L8.38049 3.90439C8.79068 4.36198 8.49681 5.14258 7.90908 5.14258Z"
                              fill={
                                orderField == "code_code" && orderDirection == 1
                                  ? "black"
                                  : "#DADADA"
                              }
                            />
                          </svg>
                          <svg
                            width="9"
                            height="6"
                            viewBox="0 0 9 6"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.90908 0.857422H4.09498H0.660442C0.072714 0.857422 -0.22115 1.63802 0.195157 2.09561L3.36644 5.58138C3.87458 6.13991 4.70107 6.13991 5.20921 5.58138L6.41528 4.25571L8.38049 2.09561C8.79068 1.63802 8.49681 0.857422 7.90908 0.857422Z"
                              fill={
                                orderField == "code_code" &&
                                orderDirection == -1
                                  ? "black"
                                  : "#DADADA"
                              }
                            />
                          </svg>

                          {/* <img src={tableup} alt="img" className="img-fluid" />
                      <img src={tabledown} alt="img" className="img-fluid" /> */}
                        </div>
                      </div>
                    </div>
                  </th>
                  <th>
                    <div className="parent-tag">
                      HASH{" "}
                      <div
                        className="filter-btn"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setOrderField("transactionHash");
                          setOrderDirection((prev) =>
                            prev == -1 ? 1 : prev == 1 ? -1 : -1
                          );
                        }}
                      >
                        <svg
                          width="9"
                          height="6"
                          viewBox="0 0 9 6"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7.90908 5.14258H4.09498H0.660442C0.072714 5.14258 -0.22115 4.36198 0.195157 3.90439L3.36644 0.418619C3.87458 -0.139912 4.70107 -0.139912 5.20921 0.418619L6.41528 1.74429L8.38049 3.90439C8.79068 4.36198 8.49681 5.14258 7.90908 5.14258Z"
                            fill={
                              orderField == "transactionHash" &&
                              orderDirection == 1
                                ? "black"
                                : "#DADADA"
                            }
                          />
                        </svg>
                        <svg
                          width="9"
                          height="6"
                          viewBox="0 0 9 6"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7.90908 0.857422H4.09498H0.660442C0.072714 0.857422 -0.22115 1.63802 0.195157 2.09561L3.36644 5.58138C3.87458 6.13991 4.70107 6.13991 5.20921 5.58138L6.41528 4.25571L8.38049 2.09561C8.79068 1.63802 8.49681 0.857422 7.90908 0.857422Z"
                            fill={
                              orderField == "transactionHash" &&
                              orderDirection == -1
                                ? "black"
                                : "#DADADA"
                            }
                          />
                        </svg>
                      </div>
                    </div>
                  </th>
                  <th>
                    <div className="parent-tag">
                      Wallet Address{" "}
                      <div className="filter-btn">
                        <div
                          className="filter-btn"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setOrderField("walletAddress");
                            setOrderDirection((prev) =>
                              prev == -1 ? 1 : prev == 1 ? -1 : -1
                            );
                          }}
                        >
                          <svg
                            width="9"
                            height="6"
                            viewBox="0 0 9 6"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.90908 5.14258H4.09498H0.660442C0.072714 5.14258 -0.22115 4.36198 0.195157 3.90439L3.36644 0.418619C3.87458 -0.139912 4.70107 -0.139912 5.20921 0.418619L6.41528 1.74429L8.38049 3.90439C8.79068 4.36198 8.49681 5.14258 7.90908 5.14258Z"
                              fill={
                                orderField == "walletAddress" &&
                                orderDirection == 1
                                  ? "black"
                                  : "#DADADA"
                              }
                            />
                          </svg>
                          <svg
                            width="9"
                            height="6"
                            viewBox="0 0 9 6"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.90908 0.857422H4.09498H0.660442C0.072714 0.857422 -0.22115 1.63802 0.195157 2.09561L3.36644 5.58138C3.87458 6.13991 4.70107 6.13991 5.20921 5.58138L6.41528 4.25571L8.38049 2.09561C8.79068 1.63802 8.49681 0.857422 7.90908 0.857422Z"
                              fill={
                                orderField == "walletAddress" &&
                                orderDirection == -1
                                  ? "black"
                                  : "#DADADA"
                              }
                            />
                          </svg>

                          {/* <img src={tableup} alt="img" className="img-fluid" />
                      <img src={tabledown} alt="img" className="img-fluid" /> */}
                        </div>
                      </div>
                    </div>
                  </th>
                  <th>
                    <div className="parent-tag">
                      Price
                      <div
                        className="filter-btn"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setOrderField("price");
                          setOrderDirection((prev) =>
                            prev == -1 ? 1 : prev == 1 ? -1 : -1
                          );
                        }}
                      >
                        <svg
                          width="9"
                          height="6"
                          viewBox="0 0 9 6"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7.90908 5.14258H4.09498H0.660442C0.072714 5.14258 -0.22115 4.36198 0.195157 3.90439L3.36644 0.418619C3.87458 -0.139912 4.70107 -0.139912 5.20921 0.418619L6.41528 1.74429L8.38049 3.90439C8.79068 4.36198 8.49681 5.14258 7.90908 5.14258Z"
                            fill={
                              orderField == "price" && orderDirection == 1
                                ? "black"
                                : "#DADADA"
                            }
                          />
                        </svg>
                        <svg
                          width="9"
                          height="6"
                          viewBox="0 0 9 6"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7.90908 0.857422H4.09498H0.660442C0.072714 0.857422 -0.22115 1.63802 0.195157 2.09561L3.36644 5.58138C3.87458 6.13991 4.70107 6.13991 5.20921 5.58138L6.41528 4.25571L8.38049 2.09561C8.79068 1.63802 8.49681 0.857422 7.90908 0.857422Z"
                            fill={
                              orderField == "price" && orderDirection == -1
                                ? "black"
                                : "#DADADA"
                            }
                          />
                        </svg>
                      </div>
                    </div>
                  </th>
                  <th>
                    <div className="parent-tag">
                      paid
                      <div
                        className="filter-btn"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setOrderField("totalSaleConverted");
                          setOrderDirection((prev) =>
                            prev == -1 ? 1 : prev == 1 ? -1 : -1
                          );
                        }}
                      >
                        <svg
                          width="9"
                          height="6"
                          viewBox="0 0 9 6"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7.90908 5.14258H4.09498H0.660442C0.072714 5.14258 -0.22115 4.36198 0.195157 3.90439L3.36644 0.418619C3.87458 -0.139912 4.70107 -0.139912 5.20921 0.418619L6.41528 1.74429L8.38049 3.90439C8.79068 4.36198 8.49681 5.14258 7.90908 5.14258Z"
                            fill={
                              orderField == "totalSaleConverted" &&
                              orderDirection == 1
                                ? "black"
                                : "#DADADA"
                            }
                          />
                        </svg>
                        <svg
                          width="9"
                          height="6"
                          viewBox="0 0 9 6"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7.90908 0.857422H4.09498H0.660442C0.072714 0.857422 -0.22115 1.63802 0.195157 2.09561L3.36644 5.58138C3.87458 6.13991 4.70107 6.13991 5.20921 5.58138L6.41528 4.25571L8.38049 2.09561C8.79068 1.63802 8.49681 0.857422 7.90908 0.857422Z"
                            fill={
                              orderField == "totalSaleConverted" &&
                              orderDirection == -1
                                ? "black"
                                : "#DADADA"
                            }
                          />
                        </svg>
                      </div>
                    </div>
                  </th>
                  {/* <th>
                  <div className="parent-tag">
                    GOD Leader{" "}
                    <div className="filter-btn">
                      <img src={tableup} alt="img" className="img-fluid" />
                      <img src={tabledown} alt="img" className="img-fluid" />
                    </div>
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    TOKEN{" "}
                    <div className="filter-btn">
                      <img src={tableup} alt="img" className="img-fluid" />
                      <img src={tabledown} alt="img" className="img-fluid" />
                    </div>
                  </div>
                </th> */}
                  <th>
                    <div className="parent-tag">
                      AMOUNT
                      <div
                        className="filter-btn"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setOrderField("dopPurchased");
                          setOrderDirection((prev) =>
                            prev == -1 ? 1 : prev == 1 ? -1 : -1
                          );
                        }}
                      >
                        <svg
                          width="9"
                          height="6"
                          viewBox="0 0 9 6"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7.90908 5.14258H4.09498H0.660442C0.072714 5.14258 -0.22115 4.36198 0.195157 3.90439L3.36644 0.418619C3.87458 -0.139912 4.70107 -0.139912 5.20921 0.418619L6.41528 1.74429L8.38049 3.90439C8.79068 4.36198 8.49681 5.14258 7.90908 5.14258Z"
                            fill={
                              orderField == "dopPurchased" &&
                              orderDirection == 1
                                ? "black"
                                : "#DADADA"
                            }
                          />
                        </svg>
                        <svg
                          width="9"
                          height="6"
                          viewBox="0 0 9 6"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7.90908 0.857422H4.09498H0.660442C0.072714 0.857422 -0.22115 1.63802 0.195157 2.09561L3.36644 5.58138C3.87458 6.13991 4.70107 6.13991 5.20921 5.58138L6.41528 4.25571L8.38049 2.09561C8.79068 1.63802 8.49681 0.857422 7.90908 0.857422Z"
                            fill={
                              orderField == "dopPurchased" &&
                              orderDirection == -1
                                ? "black"
                                : "#DADADA"
                            }
                          />
                        </svg>
                      </div>
                    </div>
                  </th>
                  <th>
                    <div className="parent-tag">
                      PAYMENT
                      <div className="filter-btn">
                        <div
                          className="filter-btn"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setOrderField("totalSaleConverted");
                            setOrderDirection((prev) =>
                              prev == -1 ? 1 : prev == 1 ? -1 : -1
                            );
                          }}
                        >
                          <svg
                            width="9"
                            height="6"
                            viewBox="0 0 9 6"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.90908 5.14258H4.09498H0.660442C0.072714 5.14258 -0.22115 4.36198 0.195157 3.90439L3.36644 0.418619C3.87458 -0.139912 4.70107 -0.139912 5.20921 0.418619L6.41528 1.74429L8.38049 3.90439C8.79068 4.36198 8.49681 5.14258 7.90908 5.14258Z"
                              fill={
                                orderField == "totalSaleConverted" &&
                                orderDirection == 1
                                  ? "black"
                                  : "#DADADA"
                              }
                            />
                          </svg>
                          <svg
                            width="9"
                            height="6"
                            viewBox="0 0 9 6"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.90908 0.857422H4.09498H0.660442C0.072714 0.857422 -0.22115 1.63802 0.195157 2.09561L3.36644 5.58138C3.87458 6.13991 4.70107 6.13991 5.20921 5.58138L6.41528 4.25571L8.38049 2.09561C8.79068 1.63802 8.49681 0.857422 7.90908 0.857422Z"
                              fill={
                                orderField == "totalSaleConverted" &&
                                orderDirection == -1
                                  ? "black"
                                  : "#DADADA"
                              }
                            />
                          </svg>

                          {/* <img src={tableup} alt="img" className="img-fluid" />
                      <img src={tabledown} alt="img" className="img-fluid" /> */}
                        </div>
                      </div>
                    </div>
                  </th>
                  <th>
                    <div className="parent-tag">
                      TYPE
                      <div className="filter-btn">
                        <div
                          className="filter-btn"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setOrderField("type");
                            setOrderDirection((prev) =>
                              prev == -1 ? 1 : prev == 1 ? -1 : -1
                            );
                          }}
                        >
                          <svg
                            width="9"
                            height="6"
                            viewBox="0 0 9 6"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.90908 5.14258H4.09498H0.660442C0.072714 5.14258 -0.22115 4.36198 0.195157 3.90439L3.36644 0.418619C3.87458 -0.139912 4.70107 -0.139912 5.20921 0.418619L6.41528 1.74429L8.38049 3.90439C8.79068 4.36198 8.49681 5.14258 7.90908 5.14258Z"
                              fill={
                                orderField == "type" && orderDirection == 1
                                  ? "black"
                                  : "#DADADA"
                              }
                            />
                          </svg>
                          <svg
                            width="9"
                            height="6"
                            viewBox="0 0 9 6"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.90908 0.857422H4.09498H0.660442C0.072714 0.857422 -0.22115 1.63802 0.195157 2.09561L3.36644 5.58138C3.87458 6.13991 4.70107 6.13991 5.20921 5.58138L6.41528 4.25571L8.38049 2.09561C8.79068 1.63802 8.49681 0.857422 7.90908 0.857422Z"
                              fill={
                                orderField == "type" && orderDirection == -1
                                  ? "black"
                                  : "#DADADA"
                              }
                            />
                          </svg>

                          {/* <img src={tableup} alt="img" className="img-fluid" />
                      <img src={tabledown} alt="img" className="img-fluid" /> */}
                        </div>
                      </div>
                    </div>
                  </th>
                  <th>
                    <div className="parent-tag">
                      my Earning
                      <div className="filter-btn">
                        <div
                          className="filter-btn"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setOrderField("myEarningConverted");
                            setOrderDirection((prev) =>
                              prev == -1 ? 1 : prev == 1 ? -1 : -1
                            );
                          }}
                        >
                          <svg
                            width="9"
                            height="6"
                            viewBox="0 0 9 6"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.90908 5.14258H4.09498H0.660442C0.072714 5.14258 -0.22115 4.36198 0.195157 3.90439L3.36644 0.418619C3.87458 -0.139912 4.70107 -0.139912 5.20921 0.418619L6.41528 1.74429L8.38049 3.90439C8.79068 4.36198 8.49681 5.14258 7.90908 5.14258Z"
                              fill={
                                orderField == "myEarningConverted" &&
                                orderDirection == 1
                                  ? "black"
                                  : "#DADADA"
                              }
                            />
                          </svg>
                          <svg
                            width="9"
                            height="6"
                            viewBox="0 0 9 6"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.90908 0.857422H4.09498H0.660442C0.072714 0.857422 -0.22115 1.63802 0.195157 2.09561L3.36644 5.58138C3.87458 6.13991 4.70107 6.13991 5.20921 5.58138L6.41528 4.25571L8.38049 2.09561C8.79068 1.63802 8.49681 0.857422 7.90908 0.857422Z"
                              fill={
                                orderField == "myEarningConverted" &&
                                orderDirection == -1
                                  ? "black"
                                  : "#DADADA"
                              }
                            />
                          </svg>

                          {/* <img src={tableup} alt="img" className="img-fluid" />
                      <img src={tabledown} alt="img" className="img-fluid" /> */}
                        </div>
                      </div>
                    </div>
                  </th>
                  {/* <th>
                  <div className="parent-tag">
                    action
                  </div>
                </th> */}
                </tr>
              </thead>
              <tbody>
                {transactions?.length > 0 ? (
                  transactions?.map((item, index) => {
                    return (
                      <>
                        <tr>
                          <td>
                            <div className="twice-text">
                              <h6>
                                {moment(item?.transactionTime).format(
                                  "DD/MM/YYYY"
                                )}
                              </h6>
                              <p>
                                {moment(item?.transactionTime).format(
                                  "hh:mm A"
                                )}{" "}
                              </p>
                            </div>
                          </td>

                          <td>
                            <div className="leadermaindata">
                              <div className="leadermainimg">
                                <img
                                  src={
                                    item?.user[0]?.imgUrl
                                      ? item?.user[0]?.imgUrl
                                      : "/assets/myteam/image.png"
                                  }
                                  alt="leaderinnerimg"
                                  className="leaderinnerimg"
                                />
                              </div>
                              <div className="leadertext">
                                <h6 className="leadermainhead">
                                  {item?.user[0]?.name}
                                </h6>
                                <p className="leadermainpara">
                                  {item?.user[0]?.role === "mini_agent"
                                    ? "Ambassador"
                                    : item?.user[0]?.role === "god_agent"
                                    ? "God Leader"
                                    : item?.user[0]?.role === "super_agent"
                                    ? "Super Leader"
                                    : item?.user[0]?.role === "agent"
                                    ? "Leader"
                                    : item?.user[0]?.role === "mega_agent"
                                    ? "Mega Leader"
                                    : ""}
                                </p>
                              </div>
                            </div>
                          </td>
                          <td>
                            <p className="codepara">
                              <CopyToClipboard
                                text={item?.code_code}
                                onCopy={(e) => textCopiedFun(index)}
                              >
                                <p>
                                  {item?.code_code?.slice(0, 3)}...
                                  {item?.code_code?.slice(
                                    item?.code_code?.length - 3,
                                    item?.code_code?.length
                                  )}
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="19"
                                    height="19"
                                    viewBox="0 0 19 19"
                                    fill="none"
                                    style={{ cursor: "pointer" }}
                                    className={copy === index && "copy-button "}
                                  >
                                    <path
                                      d="M6.95508 7.3623L6.95508 5.26245C6.95508 4.42237 6.95508 4.00202 7.11857 3.68115C7.26238 3.39891 7.49168 3.1696 7.77393 3.02579C8.09479 2.8623 8.51515 2.8623 9.35522 2.8623L13.5552 2.8623C14.3953 2.8623 14.8151 2.8623 15.136 3.02579C15.4182 3.16961 15.6479 3.39891 15.7917 3.68115C15.9552 4.00202 15.9552 4.42206 15.9552 5.26214L15.9552 9.46216C15.9552 10.3022 15.9552 10.7223 15.7917 11.0431C15.6479 11.3254 15.418 11.5552 15.1358 11.699C14.8152 11.8623 14.3958 11.8623 13.5574 11.8623L11.4551 11.8623M6.95508 7.3623L4.85522 7.3623C4.01515 7.3623 3.59479 7.3623 3.27393 7.52579C2.99168 7.66961 2.76238 7.89891 2.61857 8.18115C2.45508 8.50202 2.45508 8.92237 2.45508 9.76245L2.45508 13.9625C2.45508 14.8025 2.45508 15.2224 2.61857 15.5432C2.76238 15.8255 2.99168 16.0552 3.27393 16.199C3.59448 16.3623 4.01432 16.3623 4.85276 16.3623L9.05774 16.3623C9.89618 16.3623 10.3154 16.3623 10.636 16.199C10.9182 16.0552 11.1479 15.8252 11.2917 15.543C11.4551 15.2224 11.4551 14.8031 11.4551 13.9646L11.4551 11.8623M6.95508 7.3623L9.05523 7.3623C9.8953 7.3623 10.3151 7.3623 10.636 7.52579C10.9182 7.66961 11.1479 7.89891 11.2917 8.18115C11.4551 8.50171 11.4551 8.92156 11.4551 9.76001L11.4551 11.8623"
                                      stroke="#77868B"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                </p>
                              </CopyToClipboard>
                              {copy === index && (
                                <p className="copy-text">Copied</p>
                              )}
                            </p>
                          </td>
                          <td>
                            {/* {copy ? (
                            <span className="adadasdasdasdasdasdsad">
                              {item?.by?.slice(0, 6)}...
                              {item?.by?.slice(
                                item?.by?.length - 3,
                                item?.by?.length
                              )}{" "}
                              <svg
                                style={{ cursor: "pointer" }}
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                className=""
                              >
                                <path
                                  d="M13 10.5V13.5C13 16 12 17 9.5 17H6.5C4 17 3 16 3 13.5V10.5C3 8 4 7 6.5 7H9.5C12 7 13 8 13 10.5Z"
                                  stroke="black"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M17 6.5V9.5C17 12 16 13 13.5 13H12.7143V10.7857C12.7143 8.28571 11.7143 7.28571 9.21429 7.28571H7V6.5C7 4 8 3 10.5 3H13.5C16 3 17 4 17 6.5Z"
                                  stroke="black"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </span>
                          ) : (
                            <>
                              {item?.by && (
                                <CopyToClipboard
                                  text={item?.by}
                                  onCopy={textCopiedFun2}
                                >
                                  <span className="adadasdasdasdasdasdsad">
                                    {item?.by?.slice(0, 6)}...
                                    {item?.by?.slice(
                                      item?.by?.length - 3,
                                      item?.by?.length
                                    )}{" "}
                                    <svg
                                      style={{ cursor: "pointer" }}
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="20"
                                      height="20"
                                      viewBox="0 0 20 20"
                                      fill="none"
                                      className=""
                                    >
                                      <path
                                        d="M13 10.5V13.5C13 16 12 17 9.5 17H6.5C4 17 3 16 3 13.5V10.5C3 8 4 7 6.5 7H9.5C12 7 13 8 13 10.5Z"
                                        stroke="black"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        d="M17 6.5V9.5C17 12 16 13 13.5 13H12.7143V10.7857C12.7143 8.28571 11.7143 7.28571 9.21429 7.28571H7V6.5C7 4 8 3 10.5 3H13.5C16 3 17 4 17 6.5Z"
                                        stroke="black"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                    </svg>
                                  </span>
                                </CopyToClipboard>
                              )}
                            </>
                          )} */}
                            <p className="codepara">
                              {item?.transactionHash?.slice(0, 3)}...
                              {item?.transactionHash?.slice(
                                item?.transactionHash?.length - 3,
                                item?.transactionHash?.length
                              )}
                              <a
                                href={
                                  "https://etherscan.io/tx/" +
                                  item?.transactionHash
                                }
                                target="_blank"
                              >
                                <img
                                  width={25}
                                  height={25}
                                  src="\assets\transaction\link-icon.svg"
                                  alt="img"
                                  className="img-fluid"
                                />
                              </a>
                            </p>
                          </td>
                          <td>
                            <CopyToClipboard
                              text={item?.by}
                              onCopy={(e) => textCopiedFun2(index)}
                            >
                              <p className="adadasdasdasdasdasdsad">
                                {item?.by.slice(0, 4)}...
                                {item?.by.slice(
                                  item?.by.length - 3,
                                  item?.by.length
                                )}
                                <svg
                                  style={{ cursor: "pointer" }}
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="19"
                                  height="19"
                                  viewBox="0 0 19 19"
                                  fill="none"
                                  className={copy2 === index && "copy-button "}
                                >
                                  <path
                                    d="M6.95508 7.3623L6.95508 5.26245C6.95508 4.42237 6.95508 4.00202 7.11857 3.68115C7.26238 3.39891 7.49168 3.1696 7.77393 3.02579C8.09479 2.8623 8.51515 2.8623 9.35522 2.8623L13.5552 2.8623C14.3953 2.8623 14.8151 2.8623 15.136 3.02579C15.4182 3.16961 15.6479 3.39891 15.7917 3.68115C15.9552 4.00202 15.9552 4.42206 15.9552 5.26214L15.9552 9.46216C15.9552 10.3022 15.9552 10.7223 15.7917 11.0431C15.6479 11.3254 15.418 11.5552 15.1358 11.699C14.8152 11.8623 14.3958 11.8623 13.5574 11.8623L11.4551 11.8623M6.95508 7.3623L4.85522 7.3623C4.01515 7.3623 3.59479 7.3623 3.27393 7.52579C2.99168 7.66961 2.76238 7.89891 2.61857 8.18115C2.45508 8.50202 2.45508 8.92237 2.45508 9.76245L2.45508 13.9625C2.45508 14.8025 2.45508 15.2224 2.61857 15.5432C2.76238 15.8255 2.99168 16.0552 3.27393 16.199C3.59448 16.3623 4.01432 16.3623 4.85276 16.3623L9.05774 16.3623C9.89618 16.3623 10.3154 16.3623 10.636 16.199C10.9182 16.0552 11.1479 15.8252 11.2917 15.543C11.4551 15.2224 11.4551 14.8031 11.4551 13.9646L11.4551 11.8623M6.95508 7.3623L9.05523 7.3623C9.8953 7.3623 10.3151 7.3623 10.636 7.52579C10.9182 7.66961 11.1479 7.89891 11.2917 8.18115C11.4551 8.50171 11.4551 8.92156 11.4551 9.76001L11.4551 11.8623"
                                    stroke="#77868B"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                                {copy2 === index && (
                                  <p className="copy-text">Copied</p>
                                )}
                              </p>
                            </CopyToClipboard>
                          </td>
                          <td>
                            {/* {item?.round &&
                              jsonData?.data.saleRounds?.map((round, index) => {
                                if (round.round === item.round) {
                                  return (
                                    <div key={index}>
                                      <p>{round.salePrice}</p>
                                      
                                    </div>
                                  );
                                }
                                return null; 
                              })} */}

                            {item?.price}
                          </td>
                          <td>
                            {" "}
                            <p>${formatTokenValue(item?.totalSaleConverted)}</p>
                          </td>
                          <td> {formatTokenValue(item?.dopPurchased)} TOKEN</td>

                          <td>
                            <p className="ipara">
                              {item?.tokenSelected === "ETH"
                                ? item?.amountInvested.toFixed(5)
                                : item?.tokenSelected == "TOMI"
                                ? item?.amountInvested.toFixed(3)
                                : item?.tokenSelected === "WBTC"
                                ? item?.amountInvested.toFixed(6)
                                : item?.amountInvested.toFixed(2)}
                              {}
                              {/* <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="19"
                                height="19"
                                viewBox="0 0 19 19"
                                fill="none"
                              >
                                <path
                                  d="M9.74512 15.833C11.3364 15.833 12.8625 15.2009 13.9878 14.0756C15.113 12.9504 15.7451 11.4243 15.7451 9.83301C15.7451 8.24171 15.113 6.71558 13.9878 5.59037C12.8625 4.46515 11.3364 3.83301 9.74512 3.83301C8.15382 3.83301 6.62769 4.46515 5.50248 5.59037C4.37726 6.71558 3.74512 8.24171 3.74512 9.83301C3.74512 11.4243 4.37726 12.9504 5.50248 14.0756C6.62769 15.2009 8.15382 15.833 9.74512 15.833ZM9.74512 17.333C5.60287 17.333 2.24512 13.9753 2.24512 9.83301C2.24512 5.69076 5.60287 2.33301 9.74512 2.33301C13.8874 2.33301 17.2451 5.69076 17.2451 9.83301C17.2451 13.9753 13.8874 17.333 9.74512 17.333ZM8.99512 9.08301L8.99512 13.583L10.4951 13.583L10.4951 9.08301L8.99512 9.08301ZM8.99512 6.08301L10.4951 6.08301L10.4951 7.58301L8.99512 7.58301L8.99512 6.08301Z"
                                  fill="#77868B"
                                />
                              </svg> */}{" "}
                              {item?.tokenSelected}
                            </p>
                          </td>
                          <td>
                            <p className="ipara">
                              {item?.type}
                              {/* <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="19"
                                height="19"
                                viewBox="0 0 19 19"
                                fill="none"
                              >
                                <path
                                  d="M9.74512 15.833C11.3364 15.833 12.8625 15.2009 13.9878 14.0756C15.113 12.9504 15.7451 11.4243 15.7451 9.83301C15.7451 8.24171 15.113 6.71558 13.9878 5.59037C12.8625 4.46515 11.3364 3.83301 9.74512 3.83301C8.15382 3.83301 6.62769 4.46515 5.50248 5.59037C4.37726 6.71558 3.74512 8.24171 3.74512 9.83301C3.74512 11.4243 4.37726 12.9504 5.50248 14.0756C6.62769 15.2009 8.15382 15.833 9.74512 15.833ZM9.74512 17.333C5.60287 17.333 2.24512 13.9753 2.24512 9.83301C2.24512 5.69076 5.60287 2.33301 9.74512 2.33301C13.8874 2.33301 17.2451 5.69076 17.2451 9.83301C17.2451 13.9753 13.8874 17.333 9.74512 17.333ZM8.99512 9.08301L8.99512 13.583L10.4951 13.583L10.4951 9.08301L8.99512 9.08301ZM8.99512 6.08301L10.4951 6.08301L10.4951 7.58301L8.99512 7.58301L8.99512 6.08301Z"
                                  fill="#77868B"
                                />
                              </svg> */}
                            </p>
                          </td>
                          <td>
                            {/* ${" "}
                            {parseFloat(
                              ((item?.myIndirectEarningsEth || 0) * usdPrice ||
                                0) +
                                (item?.myIndirectEarningsUsdt || 0) +
                                ((item?.myIndirectEarningsTomi || 0) *
                                  tomiPrice || 0) +
                                (item?.myIndirectEarningsUsdc || 0) +
                                ((item?.myIndirectEarningsWbtc || 0) *
                                  btcPrice || 0)
                            )
                              ?.toFixed(2)
                              ?.toLocaleString()} */}
                            $
                            {item?.myEarningConverted !== undefined
                              ? formatTokenValue(item.myEarningConverted)
                              : 0}
                            {/* $ {formatTokenValue(item?.myEarningConverted)} */}
                          </td>
                        </tr>
                      </>
                    );
                  })
                ) : !transactionApiCalled || isLoading ? (
                  <tr>
                    <td colSpan="12" className="text-center">
                      <div className="text-center">
                        <div class="d-flex align-items-center justify-content-center">
                          <div class="spinner-border text-info" role="status">
                            <span class="sr-only"></span>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td colSpan="10" className="text-center">
                      <div className="text-center">
                        <h6
                          className=""
                          style={{
                            color: "#000",
                            textAlign: "center",
                            marginTop: 60,
                          }}
                        >
                          No data found!
                        </h6>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {dashboarddetail ? (
            ""
          ) : (
            <div className="footer-content">
              <div className="left-f">
                <h6>
                  {" "}
                  {count > 0 && count <= 10 ? (
                    <>
                      <p>
                        SHOWING 1-{count} OF {count}
                      </p>
                    </>
                  ) : count > 10 ? (
                    <>
                      <p>
                        SHOWING {page > 0 ? page * 10 + 1 : page + 1} -
                        {10 * (page + 1) > count ? count : 10 * (page + 1)} OF{" "}
                        {count}
                      </p>
                    </>
                  ) : (
                    <>
                      <p>SHOWING 0 OF 0</p>
                    </>
                  )}
                </h6>
              </div>
              <div className="right-f">
                <nav aria-label="Page navigation example">
                  <ul class="pagination">
                    <nav aria-label="Page navigation example">
                      <ul class="text-center">
                        <ReactPaginate
                            previousLabel={
                              <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
    
                                <path d="M7.88916 1.93555L5.38776 1.93555L1.99471 7.99682L4.49612 7.99682L7.88916 1.93555Z" fill="#329879" />
                                <path d="M7.88916 14.0703L5.38776 14.0703L1.99471 8.00904L3.5712 6.96117L4.49612 8.00904L7.88916 14.0703Z" fill="#329879" />
                                <path d="M8.01642 6.85742H14.9615L13.6868 9.14313H6.7417L8.01642 6.85742Z" fill="#329879" />
    
                              </svg>
                            }
                            nextLabel={
                              <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                                <path d="M9.11084 1.93555L11.6122 1.93555L15.0053 7.99682L12.5039 7.99682L9.11084 1.93555Z" fill="#329879" />
                                <path d="M9.11084 14.0703L11.6122 14.0703L15.0053 8.00904L13.4288 6.96117L12.5039 8.00904L9.11084 14.0703Z" fill="#329879" />
                                <path d="M3.31281 6.85742H10.2579L8.98314 9.14313H2.03809L3.31281 6.85742Z" fill="#329879" />
                              </svg>
                            }
                          pageClassName="page-item"
                          pageLinkClassName="page-link"
                          previousClassName="page-item"
                          previousLinkClassName="page-link"
                          nextClassName="page-item"
                          nextLinkClassName="page-link"
                          breakLabel="..."
                          breakClassName="page-item"
                          breakLinkClassName="page-link"
                          pageCount={pageCount}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={5}
                          onPageChange={handlePageChange}
                          containerClassName="pagination"
                          activeClassName="active"
                          forcePage={page}
                        ></ReactPaginate>
                      </ul>
                    </nav>
                  </ul>
                </nav>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default Datatable;

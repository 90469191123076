import { useCallback, useState } from "react";
import useWeb3 from "./useWeb3";
import {
  getdoproundcreatecontract,
  getGemsPreSaleContract,
  getIncentivPreSaleContract,
  getLEIAPreSaleContract,
  getNFAPreSaleContract,
  getAstroNovaPreSaleContract,
} from "../utils/contractHelpers";
import { useWeb3React } from "@web3-react/core";
import Environment from "../utils/Environment";
const EthPrice = () => {
  const [balance, setBalance] = useState(null);
  const { account } = useWeb3React();
  const web3 = useWeb3();
  const tokenAddress = Environment.DOPRoundSingleContract;
  const contract = getdoproundcreatecontract(tokenAddress, web3);

  const gemsAddress = Environment.GemsPreSale;
  const incentivAddress = Environment.IncentivPreSale;
  const LEIAAddress = Environment.LEIAPreSale;
  const NFAAddress = Environment.NFAPreSale;
  const AstroNovaAddress = Environment.AstroNovaPreSale;

  const gemsContractPresale = getGemsPreSaleContract(gemsAddress, web3);
  const incentivContractPresale = getIncentivPreSaleContract(
    incentivAddress,
    web3
  );

  const LEIAContractPresale = getLEIAPreSaleContract(LEIAAddress, web3);
  const NFAContractPresale = getNFAPreSaleContract(NFAAddress, web3);
  const AstroNovaContractPresale = getAstroNovaPreSaleContract(
    AstroNovaAddress,
    web3
  );

  const project = localStorage.getItem("project");

  const ethPrice = useCallback(async () => {
    if (project == "INCENTIV") {
      try {
        const buy = await incentivContractPresale.methods
          .getLatestPrice("0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE")
          .call();

        const price = parseInt(buy[0]) / 10 ** 8;
        console.log("vvvvvvvvv0", price, buy);
        return price;
      } catch (error) {
        throw error;
      }
    } else if (project == "NFA") {
      try {
        const buy = await NFAContractPresale.methods
          .getLatestPrice("0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE")
          .call();

        const price = parseInt(buy[0]) / 10 ** 8;
        console.log("vvvvvvvvv0", price, buy);
        return price;
      } catch (error) {
        throw error;
      }
    } else if (project == "ASTRANOVA") {
      try {
        const buy = await AstroNovaContractPresale.methods
          .getLatestPrice("0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE")
          .call();

        const price = parseInt(buy[0]) / 10 ** 8;
        console.log("vvvvvvvvv0", price, buy);
        return price;
      } catch (error) {
        throw error;
      }
    } else if (project == "LEIA") {
      try {
        const buy = await LEIAContractPresale.methods
          .getLatestPrice("0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE")
          .call();

        const price = parseInt(buy[0]) / 10 ** 8;
        console.log("vvvvvvvvv0", price, buy);
        return price;
      } catch (error) {
        throw error;
      }
    } else if (project == "GEMS") {
      try {
        const buy = await gemsContractPresale.methods
          .getLatestPrice("0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE")
          .call();

        const price = parseInt(buy[0]) / 10 ** 8;
        console.log("vvvvvvvvv0", price, buy);
        return price;
      } catch (error) {
        throw error;
      }
    } else {
      try {
        const buy = await contract.methods
          .getLatestPrice("0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE")
          .call();

        const price = parseInt(buy[0]) / 10 ** 8;
        console.log("vvvvvvvvv0", price, buy);
        return price;
      } catch (error) {
        throw error;
      }
    }
  }, [contract, account, web3]);
  return { ethPrice: ethPrice };
};
export default EthPrice;

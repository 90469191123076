import React, { useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import ".././myclaims.scss";
import { toast } from "react-toastify";

import Environment from "../../../utils/Environment";
import EthPrice from "../../../hooks/ethPrice";
import axios from "axios";
import AllowClaimingHook from "../../../hooks/dataSenders/claiming";
import { set } from "lodash";
import useWeb3 from "../../../hooks/useWeb3";
import { Api_Url } from "../../../utils/ApiUrl";
import Claimable from "../../../hooks/dataFetchers/claimable";
import AllowClaimHook from "../../../hooks/dataSenders/claim";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Myclaims from "../Myclaims";

import Timer from "../../../hooks/dataFetchers/timer";
import TimerNFT from "../../../hooks/dataFetchers/timerNft";

import BuyAgain from "../../../hooks/dataFetchers/buyAgain";
import Loader from "../../../hooks/loader";
import BuyAgain1 from "../../../hooks/dataFetchers/buyAgain1";
import DiscountedPrice from "../../../hooks/discountedPrice";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import GetGemsStaked from "../../../hooks/dataFetchers/getGemsStakedAmount";
import ClaimInsurance from "../../../hooks/dataSenders/claimInsurance";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

function TableRow({
  item,
  index,
  usdPrice,
  setLoarder,
  loaderr,
  setRend,
  rend,
  tomiPrice,
  btcPrice,
  pepePrice,
  linkPrice,
  uniPrice,
  dopPrice,
  openWalletModal,
  setOpenWalletModal,
  tokenListedBool,
}) {
  const history = useHistory();

  const { ethPrice } = EthPrice();

  const { buyAgain } = BuyAgain();
  const { buyAgain1 } = BuyAgain1();
  const { timer } = Timer();
  const { timerNFT } = TimerNFT();
  const { discountedPrice } = DiscountedPrice();
  const { getGemsStaked } = GetGemsStaked();
  const { claimInsurance } = ClaimInsurance();

  const web3 = useWeb3();
  const [allowedStatus, setAllowedStatus] = useState();
  const project = localStorage.getItem("project");

  const user = JSON.parse(localStorage.getItem("user"));
  let tok = localStorage.getItem("accessToken");
  const { allowClaimingHook } = AllowClaimingHook();
  const { CheckClaimableHook } = Claimable();
  const { allowClaimHook } = AllowClaimHook();

  const [showStaking, setShowStaking] = useState(false);

  const handleCloseStaking = () => setShowStaking(false);
  const handleShowStaking = () => setShowStaking(true);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const { account } = useWeb3React();
  const [claimStatus, setClaimStatus] = useState();
  const [statusState, setStatusState] = useState();
  const [ethValue, setEthValue] = useState(0.0);
  const [usdtVlaue, setUsdtValue] = useState(0.0);
  const [tomiVlaue, setTomiValue] = useState(0.0);
  const [wbtcValue, setWbtcValue] = useState(0.0);
  const [usdcValue, setUsdcValue] = useState(0.0);

  const [pepeValue, setPepeValue] = useState(0.0);
  const [linkValue, setLinkValue] = useState(0.0);
  const [uniValue, setUniValue] = useState(0.0);
  const [dopValue, setDopValue] = useState(0.0);

  const [mainTag, setMainTag] = useState("ETH");
  const [inputFeild, setInputField] = useState(0);
  const [ethPricevalue, setEthPrice] = useState(0);
  const [inputFeild1, setInputField1] = useState(0);
  const [inputFeild3, setInputField3] = useState(0);
  const [inputFeild2, setInputField2] = useState(0);
  const [roundPrice, setRountPrice] = useState(0);
  const [roundPriceEth, setRountPriceEth] = useState(0);
  const [roundPriceUsd, setRountPriceUsdt] = useState(0);
  const [roundPriceTomi, setRountPriceTomi] = useState(0);
  const [roundPriceWbtc, setRountPriceWbtc] = useState(0);
  const [roundPriceUsdc, setRountPriceUsdc] = useState(0);

  const [roundPricePepe, setRountPricePepe] = useState(0);
  const [roundPriceLink, setRountPriceLink] = useState(0);
  const [roundPriceUni, setRountPriceUni] = useState(0);
  const [roundPriceDop, setRountPriceDop] = useState(0);

  const [check, setCheck] = useState(false);
  const [inputFeildMainTomi, setInputFeildMainTomi] = useState(0);
  const [inputFeildTomi, setInputFeildTomi] = useState(0);

  const [inputFeildMainWbtc, setInputFeildMainWbtc] = useState(0);
  const [inputFeildWbtc, setInputFeildWbtc] = useState(0);

  const [inputFieldMainUsdc, setInputFieldMainUsdc] = useState(0);
  const [inputFieldUsdc, setInputFieldUsdc] = useState(0);

  const [inputFieldMainPepe, setInputFieldMainPepe] = useState(0);
  const [inputFieldPepe, setInputFieldPepe] = useState(0);

  const [inputFieldMainLink, setInputFieldMainLink] = useState(0);
  const [inputFieldLink, setInputFieldLink] = useState(0);

  const [inputFieldMainUni, setInputFieldMainUni] = useState(0);
  const [inputFieldUni, setInputFieldUni] = useState(0);

  const [inputFieldMainDop, setInputFieldMainDop] = useState(0);
  const [inputFieldDop, setInputFieldDop] = useState(0);

  const [currentRound, setCurrentRound] = useState(null);
  const [stakeIndex, setStakeIndex] = useState([]);
  const [stakedAmount, setStakedAmount] = useState(0);
  const [claimInsuranceIndexes, setClaimInsuranceIndexes] = useState([]);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [show4, setShow4] = useState(false);

  const handleClose4 = () => {
    setShow4(false);
    setInputField(0);
    setInputField1(0);
    setInputFeildMainTomi(0);
    setInputField2(0);
    setInputField3(0);
    setInputFeildTomi(0);
  };
  const handleShow4 = () => setShow4(true);

  const [show5, setShow5] = useState(false);

  const handleClose5 = () => {
    setShow5(false);
    window.location.reload();
  };
  const handleShow5 = () => setShow5(true);

  const Claiming = async (bool, round, decesion) => {
    let res = await allowClaimingHook(bool, round, decesion);
    setClaimStatus(res);
  };
  const claim = async () => {
    // Environment variables
    const Tokens = {
      eth: ethValue,
      busd: usdtVlaue,
      tomi: tomiVlaue,
      wbtc: wbtcValue,
      usdc: usdcValue,
      pepe: pepeValue,
      link: linkValue,
      uni: uniValue,
      dop: dopValue,
    };

    // Create an array to storse the Tokens variables with values greater than zero
    const tokensAddressArray = [];

    // Iterate through the Tokens object and push variables with values greater than zero
    for (const key in Tokens) {
      if (Tokens[key] > 0) {
        tokensAddressArray.push(Environment[key]);
      }
    }

    // console.log("eeeeeeeee", tokensAddressArray);
    if (!account) {
      toast.info("Connect Your Wallet");
      return;
    }
    if (account.toLowerCase() !== user?.walletAddress?.toLowerCase()) {
      toast.error("Wrong Wallet For Claim");
      return;
    }
    try {
      setLoarder(true);

      let res = await allowClaimHook(
        account,
        item[0]?._id?.round,
        tokensAddressArray
      );

      // setSelected[]
      setRend(!rend);
      setShow(false);
      setShow1(true);
      setLoarder(false);
    } catch (error) {
      toast.error("Selected Claim Failed");
      setLoarder(false);
    }
  };

  const getClaimInsuranceIndexes = () => {
    var config = {
      method: "get",
      url: `${Api_Url}/users/insured-user-transaction?walletAddress=${user?.walletAddress}&limit=500&offset=1&privateSale=${project}`,

      headers: {
        Authorization: "Bearer " + tok,
      },
    };

    axios(config)
      .then(async function (res) {
        setClaimInsuranceIndexes(res?.data?.data?.userTransaction);
      })
      .catch(function (error) {
        if (error?.response?.status == 401) {
          localStorage.removeItem("accessToken");
          history.push("/login");
        }
        // setLoader(false);
      });
  };

  const ClaimedGraph = async () => {
    let Round = item[0]?._id?.round;
    // console.log('rrrrrrrrrrrr', item[0]?._id?.round)
    var data = JSON.stringify({
      query: `query MyQuery {
  fundsClaimeds(where: {by: "${user?.walletAddress}", round: ${Round}}) {
    round
    by
    id
  }
  investedWithClaimAmounts(where: {by: "${user?.walletAddress}", round: ${Round}}) {
    
    blockTimestamp
    dopPurchased
    by
    round
    id
  }
}`,
      variables: {},
    });

    var dataGEMS = JSON.stringify({
      query: `query MyQuery {
  fundsClaimeds(where: {by: "${user?.walletAddress}", round: ${Round}}) {
    round
    by
    id
  }
  purchasedWithClaimAmounts(where: {by: "${user?.walletAddress}", round: ${Round}}) {
    
    blockTimestamp
    tokenPurchased
    by
    round
    id
  }
}`,
      variables: {},
    });

    var config = {
      method: "post",
      url:
        project == "INCENTIV"
          ? Environment?.IncentivGraph
          : parseInt(Round) < 7 && project == "DOP"
          ? Environment?.oldGraph
          : project == "DOP"
          ? Environment?.graph
          : project == "LEIA"
          ? Environment?.LEIAGraph
          : project == "OLYMPUS"
          ? Environment?.OlympusGraph
          : Environment?.GemsGraph,
      headers: {
        "Content-Type": "application/json",
      },
      data: project == "DOP" ? data : dataGEMS,
    };
    axios(config).then(async (response) => {
      setStatusState(response.data.data);
    });
  };

  const fetchStakingData = async () => {
    var data = JSON.stringify({
      query: `query MyQuery {
        stakeds(where: {isClaimed: false, staker: "${account}"}) {
          stakerStakeIndex
          transactionHash
          stakeEndTime
          staker
          isClaimed
          id
          blockTimestamp
          blockNumber
          amount
        }
      }`,
      variables: {},
    });
    var config = {
      method: "post",
      url: Environment?.GemsStakingGraph,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    await axios(config)
      .then(async function (response) {
        const resData = response.data.data.stakeds;
        if (resData.length > 0) {
          let a = resData.map((e) => {
            return e.stakerStakeIndex;
          });
          setStakeIndex(a);
        }
      })
      .catch(function (error) {
        console.log(error, "error");
      });
  };

  const getGemsStakedAmount = async () => {
    const staked = await getGemsStaked(user?.walletAddress);
    if (staked) {
      setStakedAmount(staked);
    }
  };

  useEffect(() => {
    // console.log("rewwwward", item[0]?._id?.round, claimStatus);
    const funn = async () => {
      if (project == "DOP") {
        if (parseInt(item[0]?._id?.round) < 7) {
          try {
            let res = await CheckClaimableHook(
              user?.walletAddress,
              item[0]?._id?.round,
              Environment.busd
            );
            let dumObj = res;
            if (!res?.amountTomi) {
              dumObj.amountTomi = 0;
            }
            if (!res?.amountUsdc) {
              dumObj.amountUsdc = 0;
            }
            if (!res?.amountWbtc) {
              dumObj.amountWbtc = 0;
            }
            setAllowedStatus(dumObj);
            // console.log("rewwwward", res);
          } catch (error) {
            // console.log("rewwwward", error, item[0]?._id?.round);
          }
        } else {
          let busd = await CheckClaimableHook(
            user?.walletAddress,
            item[0]?._id?.round,
            Environment.busd
          );
          let tomi = await CheckClaimableHook(
            user?.walletAddress,
            item[0]?._id?.round,
            Environment.tomi
          );
          let eth = await CheckClaimableHook(
            user?.walletAddress,
            item[0]?._id?.round,
            Environment.eth
          );

          let wbtc = await CheckClaimableHook(
            user?.walletAddress,
            item[0]?._id?.round,
            Environment.wbtc
          );
          let usdc = await CheckClaimableHook(
            user?.walletAddress,
            item[0]?._id?.round,
            Environment.usdc
          );
          setAllowedStatus({
            amountEth: eth,
            amountUsd: busd,
            amountTomi: tomi,
            amountWbtc: wbtc,
            amountUsdc: usdc,
          });
        }
      } else {
        let busd = await CheckClaimableHook(
          user?.walletAddress,
          item[0]?._id?.round,
          Environment.busd
        );

        let eth = await CheckClaimableHook(
          user?.walletAddress,
          item[0]?._id?.round,
          Environment.eth
        );

        let wbtc = await CheckClaimableHook(
          user?.walletAddress,
          item[0]?._id?.round,
          Environment.wbtc
        );
        let usdc = await CheckClaimableHook(
          user?.walletAddress,
          item[0]?._id?.round,
          Environment.usdc
        );

        let pepe = await CheckClaimableHook(
          user?.walletAddress,
          item[0]?._id?.round,
          Environment.pepe
        );
        let link = await CheckClaimableHook(
          user?.walletAddress,
          item[0]?._id?.round,
          Environment.link
        );
        let uni = await CheckClaimableHook(
          user?.walletAddress,
          item[0]?._id?.round,
          Environment.uni
        );

        let dop = await CheckClaimableHook(
          user?.walletAddress,
          item[0]?._id?.round,
          Environment.dop
        );

        setAllowedStatus({
          amountEth: eth,
          amountUsd: busd,
          amountWbtc: wbtc,
          amountUsdc: usdc,
          amountPepe: pepe,
          amountLink: link,
          amountUni: uni,
          amountDop: dop,
        });
      }
    };
    funn();
    Claiming(true, item[0]?._id?.round, null);
    if (user?.walletAddress) {
      ClaimedGraph();
      fetchStakingData();
      getGemsStakedAmount();
      getClaimInsuranceIndexes();
    }
  }, [rend, user?.walletAddress]);

  let ethrounValue = (val) => {
    let value = parseFloat(val || 0);
    let truncatedValue = Math.floor(value * 100000) / 100000;
    const ethAmount = parseFloat(truncatedValue || 0)?.toFixed(5);
    return ethAmount;
  };
  let usdtrounValue = (val) => {
    let value = parseFloat(val || 0);
    let truncatedValue = Math.floor(value * 10000) / 10000;
    const ethAmount = parseFloat(truncatedValue || 0)?.toFixed(4);
    return ethAmount;
  };
  let wbtcroundValue = (val) => {
    let value = parseFloat(val || 0);
    let truncatedValue = Math.floor(value * 10000000) / 10000000;
    const wbtcAmount = parseFloat(truncatedValue || 0)?.toFixed(6);
    return wbtcAmount;
  };
  let usdcroundValue = (val) => {
    let value = parseFloat(val || 0);
    let truncatedValue = Math.floor(value * 10000) / 10000;
    const usdcAmount = parseFloat(truncatedValue || 0)?.toFixed(4);
    return usdcAmount;
  };

  let peperoundValue = (val) => {
    let value = parseFloat(val || 0);
    let truncatedValue = Math.floor(value * 10000) / 10000;
    const pepeAmount = parseFloat(truncatedValue || 0)?.toFixed(4);
    return pepeAmount;
  };
  let linkroundValue = (val) => {
    let value = parseFloat(val || 0);
    let truncatedValue = Math.floor(value * 10000) / 10000;
    const linkAmount = parseFloat(truncatedValue || 0)?.toFixed(4);
    return linkAmount;
  };
  let uniroundValue = (val) => {
    let value = parseFloat(val || 0);
    let truncatedValue = Math.floor(value * 10000) / 10000;
    const uniAmount = parseFloat(truncatedValue || 0)?.toFixed(4);
    return uniAmount;
  };

  let doproundValue = (val) => {
    let value = parseFloat(val || 0);
    let truncatedValue = Math.floor(value * 10000) / 10000;
    const dopAmount = parseFloat(truncatedValue || 0)?.toFixed(4);
    return dopAmount;
  };

  const dataValue = () => {
    if (item[0]?._id?.tokenSelected) {
      if (item[0]?._id?.tokenSelected === "ETH") {
        setEthValue(ethrounValue(item[0]?.amount));
      } else if (item[0]?._id?.tokenSelected === "USDT") {
        setUsdtValue(usdtrounValue(item[0]?.amount));
      } else if (item[0]?._id?.tokenSelected === "WBTC") {
        setWbtcValue(wbtcroundValue(item[0]?.amount));
      } else if (item[0]?._id?.tokenSelected === "USDC") {
        setUsdcValue(usdcroundValue(item[0]?.amount));
      } else if (item[0]?._id?.tokenSelected === "DOP") {
        setDopValue(doproundValue(item[0]?.amount));
      } else if (item[0]?._id?.tokenSelected === "PEPE") {
        setPepeValue(peperoundValue(item[0]?.amount));
      } else if (item[0]?._id?.tokenSelected === "LINK") {
        setLinkValue(linkroundValue(item[0]?.amount));
      } else if (item[0]?._id?.tokenSelected === "UNI") {
        setUniValue(uniroundValue(item[0]?.amount));
      }

      if (item[1]?._id?.tokenSelected === "ETH") {
        setEthValue(ethrounValue(item[1]?.amount));
      } else if (item[1]?._id?.tokenSelected === "USDT") {
        setUsdtValue(usdtrounValue(item[1]?.amount));
      } else if (item[1]?._id?.tokenSelected === "WBTC") {
        setWbtcValue(wbtcroundValue(item[1]?.amount));
      } else if (item[1]?._id?.tokenSelected === "USDC") {
        setUsdcValue(usdcroundValue(item[1]?.amount));
      } else if (item[1]?._id?.tokenSelected === "DOP") {
        setDopValue(doproundValue(item[1]?.amount));
      } else if (item[1]?._id?.tokenSelected === "PEPE") {
        setPepeValue(peperoundValue(item[1]?.amount));
      } else if (item[1]?._id?.tokenSelected === "LINK") {
        setLinkValue(linkroundValue(item[1]?.amount));
      } else if (item[1]?._id?.tokenSelected === "UNI") {
        setUniValue(uniroundValue(item[1]?.amount));
      }

      if (item[2]?._id?.tokenSelected === "ETH") {
        setEthValue(ethrounValue(item[2]?.amount));
      } else if (item[2]?._id?.tokenSelected === "USDT") {
        setUsdtValue(usdtrounValue(item[2]?.amount));
      } else if (item[2]?._id?.tokenSelected === "WBTC") {
        setWbtcValue(wbtcroundValue(item[2]?.amount));
      } else if (item[2]?._id?.tokenSelected === "USDC") {
        setUsdcValue(usdcroundValue(item[2]?.amount));
      } else if (item[2]?._id?.tokenSelected === "DOP") {
        setDopValue(doproundValue(item[2]?.amount));
      } else if (item[2]?._id?.tokenSelected === "PEPE") {
        setPepeValue(peperoundValue(item[2]?.amount));
      } else if (item[2]?._id?.tokenSelected === "LINK") {
        setLinkValue(linkroundValue(item[2]?.amount));
      } else if (item[2]?._id?.tokenSelected === "UNI") {
        setUniValue(uniroundValue(item[2]?.amount));
      }

      if (item[3]?._id?.tokenSelected === "ETH") {
        setEthValue(ethrounValue(item[3]?.amount));
      } else if (item[3]?._id?.tokenSelected === "USDT") {
        setUsdtValue(usdtrounValue(item[3]?.amount));
      } else if (item[3]?._id?.tokenSelected === "WBTC") {
        setWbtcValue(wbtcroundValue(item[3]?.amount));
      } else if (item[3]?._id?.tokenSelected === "USDC") {
        setUsdcValue(usdcroundValue(item[3]?.amount));
      } else if (item[3]?._id?.tokenSelected === "DOP") {
        setDopValue(doproundValue(item[3]?.amount));
      } else if (item[3]?._id?.tokenSelected === "PEPE") {
        setPepeValue(peperoundValue(item[3]?.amount));
      } else if (item[3]?._id?.tokenSelected === "LINK") {
        setLinkValue(linkroundValue(item[3]?.amount));
      } else if (item[3]?._id?.tokenSelected === "UNI") {
        setUniValue(uniroundValue(item[3]?.amount));
      }

      if (item[4]?._id?.tokenSelected === "ETH") {
        setEthValue(ethrounValue(item[4]?.amount));
      } else if (item[4]?._id?.tokenSelected === "USDT") {
        setUsdtValue(usdtrounValue(item[4]?.amount));
      } else if (item[4]?._id?.tokenSelected === "WBTC") {
        setWbtcValue(wbtcroundValue(item[4]?.amount));
      } else if (item[4]?._id?.tokenSelected === "USDC") {
        setUsdcValue(usdcroundValue(item[4]?.amount));
      } else if (item[4]?._id?.tokenSelected === "DOP") {
        setDopValue(doproundValue(item[4]?.amount));
      } else if (item[4]?._id?.tokenSelected === "PEPE") {
        setPepeValue(peperoundValue(item[4]?.amount));
      } else if (item[4]?._id?.tokenSelected === "LINK") {
        setLinkValue(linkroundValue(item[4]?.amount));
      } else if (item[4]?._id?.tokenSelected === "UNI") {
        setUniValue(uniroundValue(item[4]?.amount));
      }

      if (item[5]?._id?.tokenSelected === "ETH") {
        setEthValue(ethrounValue(item[5]?.amount));
      } else if (item[5]?._id?.tokenSelected === "USDT") {
        setUsdtValue(usdtrounValue(item[5]?.amount));
      } else if (item[5]?._id?.tokenSelected === "WBTC") {
        setWbtcValue(wbtcroundValue(item[5]?.amount));
      } else if (item[5]?._id?.tokenSelected === "USDC") {
        setUsdcValue(usdcroundValue(item[5]?.amount));
      } else if (item[5]?._id?.tokenSelected === "DOP") {
        setDopValue(doproundValue(item[5]?.amount));
      } else if (item[5]?._id?.tokenSelected === "PEPE") {
        setPepeValue(peperoundValue(item[5]?.amount));
      } else if (item[5]?._id?.tokenSelected === "LINK") {
        setLinkValue(linkroundValue(item[5]?.amount));
      } else if (item[5]?._id?.tokenSelected === "UNI") {
        setUniValue(uniroundValue(item[5]?.amount));
      }

      if (item[6]?._id?.tokenSelected === "ETH") {
        setEthValue(ethrounValue(item[6]?.amount));
      } else if (item[6]?._id?.tokenSelected === "USDT") {
        setUsdtValue(usdtrounValue(item[6]?.amount));
      } else if (item[6]?._id?.tokenSelected === "WBTC") {
        setWbtcValue(wbtcroundValue(item[6]?.amount));
      } else if (item[6]?._id?.tokenSelected === "USDC") {
        setUsdcValue(usdcroundValue(item[6]?.amount));
      } else if (item[6]?._id?.tokenSelected === "DOP") {
        setDopValue(doproundValue(item[6]?.amount));
      } else if (item[6]?._id?.tokenSelected === "PEPE") {
        setPepeValue(peperoundValue(item[6]?.amount));
      } else if (item[6]?._id?.tokenSelected === "LINK") {
        setLinkValue(linkroundValue(item[6]?.amount));
      } else if (item[6]?._id?.tokenSelected === "UNI") {
        setUniValue(uniroundValue(item[6]?.amount));
      }

      if (item[7]?._id?.tokenSelected === "ETH") {
        setEthValue(ethrounValue(item[7]?.amount));
      } else if (item[7]?._id?.tokenSelected === "USDT") {
        setUsdtValue(usdtrounValue(item[7]?.amount));
      } else if (item[7]?._id?.tokenSelected === "WBTC") {
        setWbtcValue(wbtcroundValue(item[7]?.amount));
      } else if (item[7]?._id?.tokenSelected === "USDC") {
        setUsdcValue(usdcroundValue(item[7]?.amount));
      } else if (item[7]?._id?.tokenSelected === "DOP") {
        setDopValue(doproundValue(item[7]?.amount));
      } else if (item[7]?._id?.tokenSelected === "PEPE") {
        setPepeValue(peperoundValue(item[7]?.amount));
      } else if (item[7]?._id?.tokenSelected === "LINK") {
        setLinkValue(linkroundValue(item[7]?.amount));
      } else if (item[7]?._id?.tokenSelected === "UNI") {
        setUniValue(uniroundValue(item[7]?.amount));
      }
    }
  };
  const firstTimeData11 = async () => {
    try {
      let a = await ethPrice();
      if (a) {
        setEthPrice(a);
      }
    } catch (err) {
      // console.log(err.message);
    }
  };
  const firstTimeData112 = async () => {
    // if (item[0]?._id?.round > 6) {
    try {
      let a = await timer(item[0]?._id?.round);
      if (a) {
        const price = parseInt(a?.price) / 10 ** 18;
        setRountPrice(price);
      }
    } catch (err) {
      console.log(err.message, "noor");
    }
    // } else {
    //   try {
    //     let a = await timerNFT(item[0]?._id?.round);
    //     if (a) {
    //       setRountPrice(a?.price / 10 ** 18);
    //     }
    //   } catch (err) {
    //     console.log(err.message, "noor");
    //   }
    // }
  };
  useEffect(() => {
    dataValue();
    firstTimeData11();
    firstTimeData112();
  }, [item]);

  const inputField1Handler = (e) => {
    // if (item[0]?._id?.round > 6) {
    let value = e.target.value;
    let tokenPrice = 0;
    if (stakedAmount >= 18000 && currentRound == 1) {
      tokenPrice = roundPriceEth - roundPriceEth * 0.2;
    } else if (stakedAmount >= 18000 && currentRound > 1) {
      tokenPrice = roundPriceEth - roundPriceEth * 0.07;
    } else {
      tokenPrice = roundPriceEth;
    }
    if (ethValue >= parseFloat(value)) {
      if (value) {
        let amount = parseFloat((ethPricevalue * value) / tokenPrice).toFixed(
          6
        );
        let amount1 = "";
        if (tokenPrice > 0) {
          amount1 = Math.round(amount * 1e12) / 1e12;
        } else {
          amount1 = 0.0;
        }
        setInputField1(parseFloat(amount1)?.toFixed(2));
        setInputField(value);
      } else {
        setInputField1(0);
        setInputField("");
      }
    } else {
      setCheck(true);
      setInputField1(0);
      setInputField("");
      setTimeout(() => {
        setCheck(false);
      }, "1000");
    }
  };

  const inputField1Handler1 = (e) => {
    // if (item[0]?._id?.round > 6) {
    let value = e.target.value;
    let tokenPrice = 0;
    if (stakedAmount >= 18000 && currentRound == 1) {
      tokenPrice = roundPriceUsd - roundPriceUsd * 0.2;
    } else if (stakedAmount >= 18000 && currentRound > 1) {
      tokenPrice = roundPriceUsd - roundPriceUsd * 0.07;
    } else {
      tokenPrice = roundPriceUsd;
    }
    if (usdtVlaue >= parseFloat(value)) {
      if (value) {
        let amount = parseFloat(value / tokenPrice).toFixed(6);
        let amount1 = "";
        if (tokenPrice > 0) {
          amount1 = Math.round(amount * 1e12) / 1e12;
        } else {
          amount1 = 0.0;
        }
        // // console.log(
        //     parseFloat(amount1)?.toFixed(2),
        //     "parseFloat(amount1)?.toFixed(2)s"
        // );
        setInputField3(parseFloat(amount1)?.toFixed(2));
        setInputField2(value);
      } else {
        setInputField3(0);
        setInputField2("");
      }
    } else {
      setCheck(true);
      setInputField3(0);
      setInputField2("");
      setTimeout(() => {
        setCheck(false);
      }, "1000");
    }
  };

  const inputField1Handler3 = (e) => {
    let value = e.target.value;
    if (wbtcValue >= parseFloat(value)) {
      if (value) {
        let amount = parseFloat((btcPrice * value) / roundPriceWbtc).toFixed(6);
        let amount1 = "";
        if (roundPriceWbtc > 0) {
          amount1 = Math.round(amount * 1e12) / 1e12;
        } else {
          amount1 = 0.0;
        }
        // // console.log(
        //     parseFloat(amount1)?.toFixed(2),
        //     "parseFloat(amount1)?.toFixed(2)s"
        // );
        setInputFeildWbtc(parseFloat(amount1)?.toFixed(2));
        setInputFeildMainWbtc(value);
      } else {
        setInputFeildWbtc(0);
        setInputFeildMainWbtc("");
      }
    } else {
      setCheck(true);
      setInputFeildWbtc(0);
      setInputFeildMainWbtc("");
      setTimeout(() => {
        setCheck(false);
      }, "1000");
    }
  };

  const inputField1Handler4 = (e) => {
    let value = e.target.value;
    let tokenPrice = 0;

    if (stakedAmount >= 18000 && currentRound == 1) {
      tokenPrice = roundPriceUsdc - roundPriceUsdc * 0.2;
    } else if (stakedAmount >= 18000 && currentRound > 1) {
      tokenPrice = roundPriceUsdc - roundPriceUsdc * 0.07;
    } else {
      tokenPrice = roundPriceUsdc;
    }
    if (usdcValue >= parseFloat(value)) {
      if (value) {
        let amount = parseFloat(value / tokenPrice).toFixed(6);
        let amount1 = "";
        if (tokenPrice > 0) {
          amount1 = Math.round(amount * 1e12) / 1e12;
        } else {
          amount1 = 0.0;
        }
        // // console.log(
        //     parseFloat(amount1)?.toFixed(2),
        //     "parseFloat(amount1)?.toFixed(2)s"
        // );
        setInputFieldUsdc(parseFloat(amount1)?.toFixed(2));
        setInputFieldMainUsdc(value);
      } else {
        setInputFieldUsdc(0);
        setInputFieldMainUsdc("");
      }
    } else {
      setCheck(true);
      setInputFieldUsdc(0);
      setInputFieldMainUsdc("");
      setTimeout(() => {
        setCheck(false);
      }, "1000");
    }
  };

  const inputField1HandlerPepe = (e) => {
    let value = e.target.value;
    let tokenPrice = 0;

    if (stakedAmount >= 18000 && currentRound == 1) {
      tokenPrice = roundPricePepe - roundPricePepe * 0.2;
    } else if (stakedAmount >= 18000 && currentRound > 1) {
      tokenPrice = roundPricePepe - roundPricePepe * 0.07;
    } else {
      tokenPrice = roundPricePepe;
    }
    if (pepeValue >= parseFloat(value)) {
      if (value) {
        let amount = parseFloat((pepePrice * value) / tokenPrice).toFixed(6);
        let amount1 = "";
        if (tokenPrice > 0) {
          amount1 = Math.round(amount * 1e12) / 1e12;
        } else {
          amount1 = 0.0;
        }
        // // console.log(
        //     parseFloat(amount1)?.toFixed(2),
        //     "parseFloat(amount1)?.toFixed(2)s"
        // );
        setInputFieldPepe(parseFloat(amount1)?.toFixed(2));
        setInputFieldMainPepe(value);
      } else {
        setInputFieldPepe(0);
        setInputFieldMainPepe("");
      }
    } else {
      setCheck(true);
      setInputFieldPepe(0);
      setInputFieldMainPepe("");
      setTimeout(() => {
        setCheck(false);
      }, "1000");
    }
  };

  const inputField1HandlerLink = (e) => {
    let value = e.target.value;
    let tokenPrice = 0;

    if (stakedAmount >= 18000 && currentRound == 1) {
      tokenPrice = roundPriceLink - roundPriceLink * 0.2;
    } else if (stakedAmount >= 18000 && currentRound > 1) {
      tokenPrice = roundPriceLink - roundPriceLink * 0.07;
    } else {
      tokenPrice = roundPriceLink;
    }
    if (linkValue >= parseFloat(value)) {
      if (value) {
        let amount = parseFloat((linkPrice * value) / tokenPrice).toFixed(6);
        let amount1 = "";
        if (tokenPrice > 0) {
          amount1 = Math.round(amount * 1e12) / 1e12;
        } else {
          amount1 = 0.0;
        }
        // // console.log(
        //     parseFloat(amount1)?.toFixed(2),
        //     "parseFloat(amount1)?.toFixed(2)s"
        // );
        setInputFieldLink(parseFloat(amount1)?.toFixed(2));
        setInputFieldMainLink(value);
      } else {
        setInputFieldLink(0);
        setInputFieldMainLink("");
      }
    } else {
      setCheck(true);
      setInputFieldLink(0);
      setInputFieldMainLink("");
      setTimeout(() => {
        setCheck(false);
      }, "1000");
    }
  };

  const inputField1HandlerUni = (e) => {
    let value = e.target.value;
    let tokenPrice = 0;

    if (stakedAmount >= 18000 && currentRound == 1) {
      tokenPrice = roundPriceUni - roundPriceUni * 0.2;
    } else if (stakedAmount >= 18000 && currentRound > 1) {
      tokenPrice = roundPriceUni - roundPriceUni * 0.07;
    } else {
      tokenPrice = roundPriceUni;
    }
    if (uniValue >= parseFloat(value)) {
      if (value) {
        let amount = parseFloat((uniPrice * value) / tokenPrice).toFixed(6);
        let amount1 = "";
        if (tokenPrice > 0) {
          amount1 = Math.round(amount * 1e12) / 1e12;
        } else {
          amount1 = 0.0;
        }
        // // console.log(
        //     parseFloat(amount1)?.toFixed(2),
        //     "parseFloat(amount1)?.toFixed(2)s"
        // );
        setInputFieldUni(parseFloat(amount1)?.toFixed(2));
        setInputFieldMainUni(value);
      } else {
        setInputFieldUni(0);
        setInputFieldMainUni("");
      }
    } else {
      setCheck(true);
      setInputFieldUni(0);
      setInputFieldMainUni("");
      setTimeout(() => {
        setCheck(false);
      }, "1000");
    }
  };

  const inputField1HandlerDop = (e) => {
    let value = e.target.value;
    let tokenPrice = 0;

    if (stakedAmount >= 18000 && currentRound == 1) {
      tokenPrice = roundPriceDop - roundPriceDop * 0.2;
    } else if (stakedAmount >= 18000 && currentRound > 1) {
      tokenPrice = roundPriceDop - roundPriceDop * 0.07;
    } else {
      tokenPrice = roundPriceDop;
    }
    if (dopValue >= parseFloat(value)) {
      if (value) {
        let amount = parseFloat((dopPrice * value) / tokenPrice).toFixed(6);
        let amount1 = "";
        if (tokenPrice > 0) {
          amount1 = Math.round(amount * 1e12) / 1e12;
        } else {
          amount1 = 0.0;
        }
        // // console.log(
        //     parseFloat(amount1)?.toFixed(2),
        //     "parseFloat(amount1)?.toFixed(2)s"
        // );
        setInputFieldDop(parseFloat(amount1)?.toFixed(2));
        setInputFieldMainDop(value);
      } else {
        setInputFieldDop(0);
        setInputFieldMainDop("");
      }
    } else {
      setCheck(true);
      setInputFieldDop(0);
      setInputFieldMainDop("");
      setTimeout(() => {
        setCheck(false);
      }, "1000");
    }
  };

  const settingValue = (min) => {
    let minvalue = min * 0.99;
    minvalue = parseFloat(minvalue).toFixed(6);
    minvalue = Math.round(minvalue * 1e12) / 1e12;

    return (minvalue = web3.utils.toWei(minvalue.toString(), "ether"));
    // return 0
  };

  const claimInsuranceFunc = async () => {
    setLoarder(true);

    try {
      let a = await claimInsurance(claimInsuranceIndexes);
      if (a) {
        setLoarder(false);
      }
    } catch (err) {
      toast.error(err.message);
      // console.log(err);
      console.log(err, "noor");
      setLoarder(false);
    }
  };

  const deposite = async () => {
    setLoarder(true);

    {
      let dummy = [];
      let eth = settingValue(inputFeild1);
      let usdt = settingValue(inputFeild3);
      let wbtc = settingValue(inputFeildWbtc);
      let usdc = settingValue(inputFieldUsdc);

      let pepe = settingValue(inputFieldPepe);
      let link = settingValue(inputFieldLink);
      let uni = settingValue(inputFieldUni);
      let dop = settingValue(inputFieldDop);

      setLoarder(true);

      dummy.push(eth);
      dummy.push(usdt);
      dummy.push(usdc);
      dummy.push(wbtc);
      dummy.push(pepe);
      dummy.push(link);
      dummy.push(uni);
      dummy.push(dop);

      const claimableAmount = [];

      claimableAmount.push({ tokenSelected: "ETH", amount: inputFeild });

      claimableAmount.push({ tokenSelected: "USDT", amount: inputFeild2 });

      claimableAmount.push({
        tokenSelected: "USDC",
        amount: inputFieldMainUsdc,
      });

      claimableAmount.push({
        tokenSelected: "WBTC",
        amount: inputFeildMainWbtc,
      });

      claimableAmount.push({
        tokenSelected: "PEPE",
        amount: inputFieldMainPepe,
      });

      claimableAmount.push({
        tokenSelected: "LINK",
        amount: inputFieldMainLink,
      });

      claimableAmount.push({
        tokenSelected: "UNI",
        amount: inputFieldMainUni,
      });

      claimableAmount.push({
        tokenSelected: "DOP",
        amount: inputFieldMainDop,
      });

      var config = {
        method: "post",
        url: `${Api_Url}/users/claims-tomi?privateSale=${project}`,
        data: {
          round: currentRound,
          claimableAmount: claimableAmount,
        },

        headers: {
          Authorization: "Bearer " + tok,
        },
      };
      let v = await axios(config)
        .then(function (response) {
          return response.data.data;
        })
        .catch(function (error) {
          toast.error(error?.response?.data?.message);
          setLoarder(false);
        });

      if (v) {
        try {
          let a = await buyAgain1(currentRound, dummy, v, stakeIndex);
          if (a) {
            setLoarder(false);
            setShow4(false);
            setShow5(true);
            handleClose();
          }
        } catch (err) {
          toast.error(err.message);
          // console.log(err);
          console.log(err, "noor");
          setLoarder(false);
        }
      }
    }
  };
  const maxUsd = () => {
    // if (item[0]?._id?.round > 6) {

    let tokenPrice = 0;

    if (stakedAmount >= 18000 && currentRound == 1) {
      tokenPrice = roundPriceUsd - roundPriceUsd * 0.2;
    } else if (stakedAmount >= 18000 && currentRound > 1) {
      tokenPrice = roundPriceUsd - roundPriceUsd * 0.07;
    } else {
      tokenPrice = roundPriceUsd;
    }

    let value = usdtVlaue;
    let amount = parseFloat(value / tokenPrice).toFixed(6);
    let amount1 = "";

    if (tokenPrice > 0) {
      amount1 = Math.round(amount * 1e12) / 1e12;
    } else {
      amount1 = 0.0;
    }
    setInputField3(parseFloat(amount1 * 0.3)?.toFixed(2));
    setInputField2(value * 0.3);
  };
  const ethMax = () => {
    // if (item[0]?._id?.round > 6) {

    let tokenPrice = 0;

    if (stakedAmount >= 18000 && currentRound == 1) {
      tokenPrice = roundPriceEth - roundPriceEth * 0.2;
    } else if (stakedAmount >= 18000 && currentRound > 1) {
      tokenPrice = roundPriceEth - roundPriceEth * 0.07;
    } else {
      tokenPrice = roundPriceEth;
    }

    let value = parseFloat(ethValue);
    let amount = parseFloat((ethPricevalue * value) / tokenPrice).toFixed(6);
    let amount1 = "";
    if (tokenPrice > 0) {
      amount1 = Math.round(amount * 1e12) / 1e12;
    } else {
      amount1 = 0.0;
    }
    setInputField1(parseFloat(amount1 * 0.3)?.toFixed(2));
    setInputField(value * 0.3);
  };

  const wbtcMax = () => {
    let value = wbtcValue;
    let amount = parseFloat((btcPrice * value) / roundPriceWbtc).toFixed(6);
    let amount1 = "";
    if (roundPriceWbtc > 0) {
      amount1 = Math.round(amount * 1e12) / 1e12;
    } else {
      amount1 = 0.0;
    }
    setInputFeildWbtc(parseFloat(amount1 * 0.3)?.toFixed(2));
    setInputFeildMainWbtc(value * 0.3);
  };

  const usdcMax = () => {
    let tokenPrice = 0;

    if (stakedAmount >= 18000 && currentRound == 1) {
      tokenPrice = roundPriceUsdc - roundPriceUsdc * 0.2;
    } else if (stakedAmount >= 18000 && currentRound > 1) {
      tokenPrice = roundPriceUsdc - roundPriceUsdc * 0.07;
    } else {
      tokenPrice = roundPriceUsdc;
    }

    let value = usdcValue;
    let amount = parseFloat(value / tokenPrice).toFixed(6);
    let amount1 = "";

    if (tokenPrice > 0) {
      amount1 = Math.round(amount * 1e12) / 1e12;
    } else {
      amount1 = 0.0;
    }
    setInputFieldUsdc(parseFloat(amount1 * 0.3)?.toFixed(2));
    setInputFieldMainUsdc(value * 0.3);
  };

  const pepeMax = () => {
    let tokenPrice = 0;

    if (stakedAmount >= 18000 && currentRound == 1) {
      tokenPrice = roundPricePepe - roundPricePepe * 0.2;
    } else if (stakedAmount >= 18000 && currentRound > 1) {
      tokenPrice = roundPricePepe - roundPricePepe * 0.07;
    } else {
      tokenPrice = roundPricePepe;
    }
    let value = pepeValue;
    let amount = parseFloat((pepePrice * value) / tokenPrice).toFixed(6);
    let amount1 = "";

    if (tokenPrice > 0) {
      amount1 = Math.round(amount * 1e12) / 1e12;
    } else {
      amount1 = 0.0;
    }
    setInputFieldPepe(parseFloat(amount1 * 0.3)?.toFixed(2));
    setInputFieldMainPepe(value * 0.3);
  };

  const linkMax = () => {
    let tokenPrice = 0;

    if (stakedAmount >= 18000 && currentRound == 1) {
      tokenPrice = roundPricePepe - roundPricePepe * 0.2;
    } else if (stakedAmount >= 18000 && currentRound > 1) {
      tokenPrice = roundPricePepe - roundPricePepe * 0.07;
    } else {
      tokenPrice = roundPricePepe;
    }

    let value = linkValue;
    let amount = parseFloat((linkPrice * value) / tokenPrice).toFixed(6);
    let amount1 = "";

    if (tokenPrice > 0) {
      amount1 = Math.round(amount * 1e12) / 1e12;
    } else {
      amount1 = 0.0;
    }
    setInputFieldLink(parseFloat(amount1 * 0.3)?.toFixed(2));
    setInputFieldMainLink(value * 0.3);
  };

  const uniMax = () => {
    let tokenPrice = 0;

    if (stakedAmount >= 18000 && currentRound == 1) {
      tokenPrice = roundPriceUni - roundPriceUni * 0.2;
    } else if (stakedAmount >= 18000 && currentRound > 1) {
      tokenPrice = roundPriceUni - roundPriceUni * 0.07;
    } else {
      tokenPrice = roundPriceUni;
    }

    let value = uniValue;
    let amount = parseFloat((uniPrice * value) / tokenPrice).toFixed(6);
    let amount1 = "";

    if (tokenPrice > 0) {
      amount1 = Math.round(amount * 1e12) / 1e12;
    } else {
      amount1 = 0.0;
    }
    setInputFieldUni(parseFloat(amount1 * 0.3)?.toFixed(2));
    setInputFieldMainUni(value * 0.3);
  };

  const dopMax = () => {
    let tokenPrice = 0;

    if (stakedAmount >= 18000 && currentRound == 1) {
      tokenPrice = roundPriceDop - roundPriceDop * 0.2;
    } else if (stakedAmount >= 18000 && currentRound > 1) {
      tokenPrice = roundPriceDop - roundPriceDop * 0.07;
    } else {
      tokenPrice = roundPriceDop;
    }

    let value = dopValue;
    let amount = parseFloat((dopPrice * value) / tokenPrice).toFixed(6);
    let amount1 = "";

    if (tokenPrice > 0) {
      amount1 = Math.round(amount * 1e12) / 1e12;
    } else {
      amount1 = 0.0;
    }
    setInputFieldDop(parseFloat(amount1 * 0.3)?.toFixed(2));
    setInputFieldMainDop(value * 0.3);
  };

  const discountEth = async (e) => {
    try {
      let a = await discountedPrice(e, Environment.eth);
      if (a > 0) {
        setRountPriceEth(a);
      } else {
        setRountPriceEth(roundPrice);
      }
    } catch (err) {
      console.log(err, "nooor");
    }
  };

  const discountUsdt = async (e) => {
    try {
      let a = await discountedPrice(e, Environment.busd);
      if (a > 0) {
        setRountPriceUsdt(a);
      } else {
        setRountPriceUsdt(roundPrice);
      }
    } catch (err) {
      console.log(err, "nooor");
    }
  };

  const discountWbtc = async (e) => {
    try {
      let a = await discountedPrice(e, Environment.wbtc);

      if (a > 0) {
        setRountPriceWbtc(a);
      } else {
        setRountPriceWbtc(roundPrice);
      }
    } catch (err) {
      console.log(err, "nooor");
    }
  };
  const discountUsdc = async (e) => {
    try {
      let a = await discountedPrice(e, Environment.usdc);
      if (a > 0) {
        setRountPriceUsdc(a);
      } else {
        setRountPriceUsdc(roundPrice);
      }
    } catch (err) {
      console.log(err, "nooor");
    }
  };

  const discountPepe = async (e) => {
    try {
      let a = await discountedPrice(e, Environment.pepe);
      if (a > 0) {
        setRountPricePepe(a);
      } else {
        setRountPricePepe(roundPrice);
      }
    } catch (err) {
      console.log(err, "nooor");
    }
  };
  const discountLink = async (e) => {
    try {
      let a = await discountedPrice(e, Environment.link);
      if (a > 0) {
        setRountPriceLink(a);
      } else {
        setRountPriceLink(roundPrice);
      }
    } catch (err) {
      console.log(err, "nooor");
    }
  };
  const discountUni = async (e) => {
    try {
      let a = await discountedPrice(e, Environment.uni);
      if (a > 0) {
        setRountPriceUni(a);
      } else {
        setRountPriceUni(roundPrice);
      }
    } catch (err) {
      console.log(err, "nooor");
    }
  };

  const discountDop = async (e) => {
    try {
      let a = await discountedPrice(e, Environment.dop);
      if (a > 0) {
        setRountPriceDop(a);
      } else {
        setRountPriceDop(roundPrice);
      }
    } catch (err) {
      console.log(err, "nooor");
    }
  };

  const renderTooltip = (props) => (
    <Tooltip id="custom-tooltip-prices" {...props}>
      <div className="text">
        <p>ETH</p>
        <h6>{ethValue} ETH</h6>
      </div>
      <div className="text">
        <p>USDT</p>
        <h6>{usdtVlaue} USDT</h6>
      </div>
      <div className="text">
        <p>WBTC</p>
        <h6>{wbtcValue} WBTC</h6>
      </div>
      <div className="text">
        <p>USDC</p>
        <h6>{usdcValue} USDC</h6>
      </div>
      <div className="text">
        <p>PEPE</p>
        <h6>{pepeValue} PEPE</h6>
      </div>
      <div className="text">
        <p>LINK</p>
        <h6>{linkValue} LINK</h6>
      </div>
      <div className="text">
        <p>UNI</p>
        <h6>{uniValue} UNI</h6>
      </div>
      <div className="text">
        <p>DOP</p>
        <h6>{dopValue} DOP</h6>
      </div>
    </Tooltip>
  );

  return (
    <>
      {loaderr && <Loader />}
      <tr>
        <td className="round">Round {item[0]?._id?.round}</td>
        <td>
          <div className="twice-text">
            <p>
              ~$
              {parseFloat(
                parseFloat(parseFloat(ethValue) * usdPrice) +
                  parseFloat(usdtVlaue) +
                  parseFloat(usdcValue) +
                  parseFloat(wbtcValue) * btcPrice +
                  parseFloat(dopValue) * dopPrice +
                  parseFloat(pepeValue) * pepePrice +
                  parseFloat(linkValue) * linkPrice +
                  parseFloat(uniValue) * uniPrice
              )
                .toFixed(2)
                ?.toLocaleString()}
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip}
                className="custom-tooltip-claim"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M11 17H13V11H11V17ZM12 9C12.2833 9 12.521 8.904 12.713 8.712C12.905 8.52 13.0007 8.28267 13 8C13 7.71667 12.904 7.47933 12.712 7.288C12.52 7.09667 12.2827 7.00067 12 7C11.7167 7 11.4793 7.096 11.288 7.288C11.0967 7.48 11.0007 7.71733 11 8C11 8.28333 11.096 8.521 11.288 8.713C11.48 8.905 11.7173 9.00067 12 9ZM12 22C10.6167 22 9.31667 21.7373 8.1 21.212C6.88333 20.6867 5.825 19.9743 4.925 19.075C4.025 18.175 3.31267 17.1167 2.788 15.9C2.26333 14.6833 2.00067 13.3833 2 12C2 10.6167 2.26267 9.31667 2.788 8.1C3.31333 6.88333 4.02567 5.825 4.925 4.925C5.825 4.025 6.88333 3.31267 8.1 2.788C9.31667 2.26333 10.6167 2.00067 12 2C13.3833 2 14.6833 2.26267 15.9 2.788C17.1167 3.31333 18.175 4.02567 19.075 4.925C19.975 5.825 20.6877 6.88333 21.213 8.1C21.7383 9.31667 22.0007 10.6167 22 12C22 13.3833 21.7373 14.6833 21.212 15.9C20.6867 17.1167 19.9743 18.175 19.075 19.075C18.175 19.975 17.1167 20.6877 15.9 21.213C14.6833 21.7383 13.3833 22.0007 12 22ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20Z"
                    fill="#797979"
                  />
                </svg>
              </OverlayTrigger>
            </p>
          </div>
        </td>
        {(parseFloat(allowedStatus?.amountEth) > 0 ||
          parseFloat(allowedStatus?.amountUsd) > 0 ||
          parseFloat(
            allowedStatus?.amountWbtc ? allowedStatus?.amountWbtc : 0
          ) > 0 ||
          parseFloat(
            allowedStatus?.amountUsdc ? allowedStatus?.amountUsdc : 0
          ) > 0 ||
          parseFloat(
            allowedStatus?.amountPepe ? allowedStatus?.amountPepe : 0
          ) > 0 ||
          parseFloat(
            allowedStatus?.amountLink ? allowedStatus?.amountLink : 0
          ) > 0 ||
          parseFloat(allowedStatus?.amountUni ? allowedStatus?.amountUni : 0) >
            0 ||
          parseFloat(allowedStatus?.amountDop ? allowedStatus?.amountDop : 0) >
            0) &&
        claimStatus ? (
          <td className="yellow111">Approved</td>
        ) : statusState?.fundsClaimeds?.length > 0 ? (
          <td className="green111"> Claimed </td>
        ) : statusState?.investedWithClaimAmounts?.length > 0 ? (
          <td className="green111"> Rebought </td>
        ) : (
          <td className="red111">Pending</td>
        )}

        <td>
          <button
            onClick={() => {
              if (project == "GEMS") {
                handleShowStaking();
              } else if (
                project == "INCENTIV" ||
                project == "LEIA" ||
                project == "OLYMPUS"
              ) {
                if (stakedAmount >= 18000) {
                  setShow(true);
                } else {
                  handleShowStaking();
                }
              }
            }}
            className={
              (parseFloat(allowedStatus?.amountUsd) === 0 &&
                parseFloat(allowedStatus?.amountEth) === 0 &&
                parseFloat(allowedStatus?.amountWbtc) === 0 &&
                parseFloat(allowedStatus?.amountUsdc) === 0 &&
                parseFloat(allowedStatus?.amountPepe) === 0 &&
                parseFloat(allowedStatus?.amountLink) === 0 &&
                parseFloat(allowedStatus?.amountUni) === 0 &&
                parseFloat(allowedStatus?.amountDop) === 0) ||
              !claimStatus ||
              statusState?.investedWithClaimAmounts?.length > 0
                ? "claimedbtn"
                : "claim-seperate"
            }
            // className="claimedbtn"
            disabled={
              (parseFloat(allowedStatus?.amountUsd) === 0 &&
                parseFloat(allowedStatus?.amountEth) === 0 &&
                parseFloat(allowedStatus?.amountWbtc) === 0 &&
                parseFloat(allowedStatus?.amountUsdc) === 0 &&
                parseFloat(allowedStatus?.amountPepe) === 0 &&
                parseFloat(allowedStatus?.amountLink) === 0 &&
                parseFloat(allowedStatus?.amountUni) === 0 &&
                parseFloat(allowedStatus?.amountDop) === 0) ||
              !claimStatus ||
              statusState?.investedWithClaimAmounts?.length > 0
            }
            style={{ margin: "0", maxWidth: "204px" }}
          >
            Claim 30%
          </button>
        </td>

        <td>
          <button
            onClick={() => {
              if (
                project == "INCENTIV" ||
                project == "LEIA" ||
                project == "OLYMPUS"
              ) {
                claimInsuranceFunc();
              }
            }}
            className={!tokenListedBool ? "claimedbtn" : "claim-seperate"}
            // className="claimedbtn"
            disabled={!tokenListedBool}
            style={{ margin: "0", marginLeft: "auto", maxWidth: "204px" }}
          >
            claim 70%
          </button>
        </td>
      </tr>

      <Modal className="claimmodal" show={show1} onHide={handleClose1} centered>
        <Modal.Header closeButton>
          <Modal.Title>Claim</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parentcontent">
            <img src="/assets/check.svg" alt="img" className="img-fluid" />
            <p>Amount claimed successfully</p>
          </div>
          <button className="ok" onClick={handleClose1}>
            Okay
          </button>
        </Modal.Body>
      </Modal>

      <Modal
        className="claimmodal set-custom-width-claim"
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Claim</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modalcard">
            <div className="cardparent">
              <h3>Total Amount</h3>
              <h5>
                $
                {parseFloat(
                  parseFloat(parseFloat(ethValue) * usdPrice) +
                    parseFloat(usdtVlaue) +
                    parseFloat(usdcValue) +
                    parseFloat(wbtcValue) * btcPrice +
                    parseFloat(dopValue) * dopPrice +
                    parseFloat(pepeValue) * pepePrice +
                    parseFloat(linkValue) * linkPrice +
                    parseFloat(uniValue) * uniPrice
                )
                  .toFixed(2)
                  ?.toLocaleString()}
              </h5>
              <h6>
                {ethValue} ETH + {usdtVlaue} USDT + {usdcValue} USDC +{" "}
                {dopValue} DOP + {wbtcValue} WBTC + {pepeValue} PEPE +{" "}
                {linkValue} LINK + {uniValue} UNI
              </h6>
            </div>
          </div>
          <div className="twice-content">
            <div className="inner-main">
              <h2 className="claimall">Claim All Now</h2>
              <p className="para">
                Your claim funds amount will be transferred to your wallet
                instantly.
              </p>
              <button
                className="claimbtn"
                onClick={() => {
                  claim();
                }}
              >
                Claim ALL NOW
              </button>
            </div>
            <div className="inner-main">
              {project == "INCENTIV" ||
              project == "LEIA" ||
              project == "OLYMPUS" ? (
                <>
                  <h2 className="claimall">Buy ${project} with Claims</h2>
                  <p className="para">
                    Buy ${project} with all or partial amount of your available
                    claims. This purchase will not result in any commissions for
                    you.
                  </p>
                  <button
                    className="claimbtn"
                    onClick={() => {
                      if (!account) {
                        toast.info("Connect Your Wallet");
                        return;
                      }
                      if (
                        account.toLowerCase() !==
                        user?.walletAddress?.toLowerCase()
                      ) {
                        toast.error("Wrong Wallet For Claim");
                        return;
                      }

                      setLoarder(true);
                      setCurrentRound(item[0]?._id?.round);

                      handleClose1();
                      setShow4(true);

                      discountUsdt(item[0]?._id?.round);
                      discountEth(item[0]?._id?.round);
                      discountWbtc(item[0]?._id?.round);
                      discountUsdc(item[0]?._id?.round);

                      discountPepe(item[0]?._id?.round);
                      discountLink(item[0]?._id?.round);
                      discountUni(item[0]?._id?.round);
                      discountDop(item[0]?._id?.round);

                      setTimeout(() => {
                        setLoarder(false);
                      }, "3000");
                    }}
                  >
                    Buy ${project} WITH CLAIMS
                  </button>
                </>
              ) : null}
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="claimmodal set-custom-width-claim"
        show={show4}
        onHide={handleClose4}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Buy ${project}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="doppara">
            You will be able to purchase ${project} with full or partial amount
            of your available claims. In case of partial amount, the remaining
            available claims will be sent to your wallet in the same transaction
          </p>
          <div className="dopparent">
            {ethValue > 0 ? (
              <div className="twice-field mb-4">
                <div className="option-field">
                  <div className="material-textfield">
                  <label>Amount ETH</label>
                    <input
                      onChange={inputField1Handler}
                      value={inputFeild}
                      onWheel={(e) => e.target.blur()}
                      type="number"
                      placeholder="Enter ETH amount"
                    />
                   
                  </div>
                  <a onClick={ethMax}>Max</a>
                  <h6 className="specific-value-text">
                    ETH Available for Claim:{" "}
                    <span>
                      {parseFloat(ethValue - inputFeild).toFixed(5)} ETH
                    </span>
                  </h6>
                </div>
              </div>
            ) : (
              ""
            )}
            {usdtVlaue > 0 ? (
              <div className="twice-field mb-4">
                <div className="option-field">
                  <div className="material-textfield">
                  <label>Amount USDT</label>
                    <input
                      onChange={inputField1Handler1}
                      value={inputFeild2}
                      onWheel={(e) => e.target.blur()}
                      type="number"
                      placeholder="Enter USDT amount"
                    />
                   
                  </div>
                  <a onClick={maxUsd}>Max</a>
                  <h6 className="specific-value-text">
                    USDT Available for Claim:{" "}
                    <span>{usdtVlaue - inputFeild2} USDT</span>
                  </h6>
                </div>
              </div>
            ) : (
              ""
            )}
            {wbtcValue > 0 ? (
              <div className="twice-field ">
                <div className="option-field">
                  <div className="material-textfield">
                  <label>Amount WBTC</label>
                    <input
                      onChange={inputField1Handler3}
                      value={inputFeildMainWbtc}
                      onWheel={(e) => e.target.blur()}
                      type="number"
                      placeholder="Enter WBTC amount"
                    />
                   
                  </div>
                  <a onClick={wbtcMax}>Max</a>
                  <h6 className="specific-value-text">
                    WBTC Available for Claim:{" "}
                    <span>{wbtcValue - inputFeildMainWbtc} WBTC</span>
                  </h6>
                </div>
              </div>
            ) : (
              ""
            )}
            {usdcValue > 0 ? (
              <div className="twice-field mt-3">
                <div className="option-field">
                  <div className="material-textfield">
                  <label>Amount USDC</label>
                    <input
                      onChange={inputField1Handler4}
                      value={inputFieldMainUsdc}
                      onWheel={(e) => e.target.blur()}
                      type="number"
                      placeholder="Enter USDC amount"
                    />
                    
                  </div>
                  <a onClick={usdcMax}>Max</a>
                  <h6 className="specific-value-text">
                    USDC Available for Claim:{" "}
                    <span>{usdcValue - inputFieldMainUsdc} USDC</span>
                  </h6>
                </div>
              </div>
            ) : (
              ""
            )}
            {dopValue > 0 ? (
              <div className="twice-field mt-3">
                <div className="option-field">
                  <div className="material-textfield">
                  <label>Amount DOP</label>
                    <input
                      onChange={inputField1HandlerDop}
                      value={inputFieldMainDop}
                      onWheel={(e) => e.target.blur()}
                      type="number"
                      placeholder="Enter DOP amount"
                    />
                   
                  </div>
                  <a onClick={dopMax}>Max</a>
                  <h6 className="specific-value-text">
                    DOP Available for Claim:{" "}
                    <span>{dopValue - inputFieldMainDop} DOP</span>
                  </h6>
                </div>
              </div>
            ) : (
              ""
            )}
            {pepeValue > 0 ? (
              <div className="twice-field mt-3">
                <div className="option-field">
                  <div className="material-textfield">
                  <label>Amount PEPE</label>
                    <input
                      onChange={inputField1HandlerPepe}
                      value={inputFieldMainPepe}
                      onWheel={(e) => e.target.blur()}
                      type="number"
                      placeholder="Enter PEPE amount"
                    />
                   
                  </div>
                  <a onClick={pepeMax}>Max</a>
                  <h6 className="specific-value-text">
                    PEPE Available for Claim:{" "}
                    <span>{pepeValue - inputFieldMainPepe} PEPE</span>
                  </h6>
                </div>
              </div>
            ) : (
              ""
            )}{" "}
            {linkValue > 0 ? (
              <div className="twice-field mt-3">
                <div className="option-field">
                  <div className="material-textfield">
                  <label>Amount LINK</label>
                    <input
                      onChange={inputField1HandlerLink}
                      value={inputFieldMainLink}
                      onWheel={(e) => e.target.blur()}
                      type="number"
                      placeholder="Enter LINK amount"
                    />
                   
                  </div>
                  <a onClick={linkMax}>Max</a>
                  <h6 className="specific-value-text">
                    LINK Available for Claim:{" "}
                    <span>{linkValue - inputFieldMainLink} LINK</span>
                  </h6>
                </div>
              </div>
            ) : (
              ""
            )}{" "}
            {uniValue > 0 ? (
              <div className="twice-field mt-3">
                <div className="option-field">
                  <div className="material-textfield">
                  <label>Amount UNI</label>
                    <input
                      onChange={inputField1HandlerUni}
                      value={inputFieldMainUni}
                      onWheel={(e) => e.target.blur()}
                      type="number"
                      placeholder="Enter UNI amount"
                    />
                    
                  </div>
                  <a onClick={uniMax}>Max</a>
                  <h6 className="specific-value-text">
                    UNI Available for Claim:{" "}
                    <span>{uniValue - inputFieldMainUni} UNI</span>
                  </h6>
                </div>
              </div>
            ) : (
              ""
            )}
            {check && (
              <div className="">
                <p className="error-msg">
                  Amount must be smaller than balance amount
                </p>
              </div>
            )}
          </div>
          <div className="twice-box">
            <div className="parentdoptext">
              <h6>Balance</h6>

              <p>
                {ethValue > 0 && `${ethValue} ETH`}
                {ethValue > 0 && usdtVlaue > 0 && ` + `}
                {usdtVlaue > 0 && `${usdtVlaue} USDT`}
                {(ethValue > 0 || usdtVlaue > 0) && usdcValue > 0 && ` + `}
                {usdcValue > 0 && `${usdcValue} USDC`}
                {(ethValue > 0 || usdtVlaue > 0 || usdcValue > 0) &&
                  pepeValue > 0 &&
                  ` + `}
                {pepeValue > 0 && `${pepeValue} PEPE`}
                {(ethValue > 0 ||
                  usdtVlaue > 0 ||
                  usdcValue > 0 ||
                  pepeValue > 0) &&
                  wbtcValue > 0 &&
                  ` + `}
                {wbtcValue > 0 && `${wbtcValue} WBTC`}
                {(ethValue > 0 ||
                  usdtVlaue > 0 ||
                  usdcValue > 0 ||
                  pepeValue > 0 ||
                  wbtcValue > 0) &&
                  linkValue > 0 &&
                  ` + `}
                {linkValue > 0 && `${linkValue} LINK`}
                {(ethValue > 0 ||
                  usdtVlaue > 0 ||
                  usdcValue > 0 ||
                  pepeValue > 0 ||
                  wbtcValue > 0 ||
                  linkValue > 0) &&
                  uniValue > 0 &&
                  ` + `}
                {uniValue > 0 && `${uniValue} UNI`}
                {(ethValue > 0 ||
                  usdtVlaue > 0 ||
                  usdcValue > 0 ||
                  pepeValue > 0 ||
                  wbtcValue > 0 ||
                  linkValue > 0 ||
                  uniValue > 0) &&
                  dopValue > 0 &&
                  ` + `}
                {dopValue > 0 && `${dopValue} DOP`}
              </p>
            </div>
            <div className="parentdoptext">
              <h6>{project} Price</h6>
              {stakedAmount >= 18000 && currentRound == 1 ? (
                <p>{roundPrice > 0 ? roundPrice - roundPrice * 0.2 : "0.00"}</p>
              ) : stakedAmount >= 18000 && currentRound > 1 ? (
                <p>
                  {roundPrice > 0 ? roundPrice - roundPrice * 0.07 : "0.00"}
                </p>
              ) : (
                <p>{roundPrice > 0 ? roundPrice : "0.00"}</p>
              )}
            </div>
            <div className="parentdoptext">
              <h6>Total {project}</h6>
              <p>
                {" "}
                {inputFeild1 > 0 ||
                inputFeild3 > 0 ||
                inputFieldUsdc > 0 ||
                inputFeildWbtc > 0 ||
                inputFieldPepe > 0 ||
                inputFieldLink > 0 ||
                inputFieldUni > 0 ||
                inputFieldDop > 0
                  ? parseFloat(inputFeild1) +
                    parseFloat(inputFeild3) +
                    parseFloat(inputFeildWbtc) +
                    parseFloat(inputFieldUsdc) +
                    parseFloat(inputFieldPepe) +
                    parseFloat(inputFieldLink) +
                    parseFloat(inputFieldUni) +
                    parseFloat(inputFieldDop)
                  : "0.00"}
              </p>
            </div>
          </div>
          <div className="twicebtns">
            <button
              className="back"
              onClick={() => {
                handleClose4();
              }}
            >
              Go Back
            </button>
            <button
              className="buy"
              onClick={() => {
                deposite();
              }}
            >
              Buy {project}
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal className="claimmodal" show={show5} onHide={handleClose5} centered>
        <Modal.Header closeButton>
          <Modal.Title>Claim</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parentcontent">
            <img src="/assets/check.svg" alt="img" className="img-fluid" />
            <p className="purchasedpara">You have successfully purchased</p>
            <p className="forspan">
              <span>
                {parseFloat(inputFeild1) +
                  parseFloat(inputFeild3) +
                  parseFloat(inputFieldUsdc) +
                  parseFloat(inputFeildWbtc) +
                  parseFloat(inputFieldPepe) +
                  parseFloat(inputFieldLink) +
                  parseFloat(inputFieldUni) +
                  parseFloat(inputFieldDop)}{" "}
                {project}{" "}
              </span>
              for{" "}
              <span>
                {inputFeild} ETH , {inputFeild2} USDT, {inputFieldMainUsdc}{" "}
                USDC, {inputFieldMainDop} DOP, {inputFeildMainWbtc} WBTC,{" "}
                {inputFieldMainPepe} PEPE, {inputFieldMainLink} LINK,{" "}
                {inputFieldMainUni} UNI
              </span>
            </p>
          </div>
          <button className="ok" onClick={handleClose5}>
            Okay
          </button>
        </Modal.Body>
      </Modal>
      <Modal
        className="claimmodal"
        show={showStaking}
        onHide={handleCloseStaking}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Claim</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parentcontent">
            <img src="/assets/Earningss.png" alt="img" className="img-fluid" />
            {/* <p>Amount claimed successfully</p> */}
          </div>
          <div className="onlymodbtnns">
            <button
              className="  claimbtn m-0"
              onClick={() => {
                handleCloseStaking();
                setShow(true);
              }}
            >
              proceed to claim
            </button>
            <button className=" ok " onClick={() => history.push("/staking")}>
              Go Premium
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default TableRow;

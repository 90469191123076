import React, { useState, useEffect, useMemo, useRef } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Landing from "./components/landing/Landing";
import Signup from "./components/Signup/Signup";
import Login from "./components/Signup/Login";
import Dashboard from "./components/Dashboard/Dashboard";
import Dashboarddetail from "./components/Dashboard/Dashboarddetail";
import AllTransaction from "./components/AllTransaction/AllTransaction";
import Project from "./components/Project/Project";
import Myclaims from "./components/Myclaims/Myclaims";
import Leaders from "./components/Leaders/Leaders";
import Myteam from "./components/Myteam/Myteam";
import ViewDetail from "./components/Leaders/ViewDetail";
import Profile from "./components/Profile/Profile";
import Editprofile from "./components/Profile/Editprofile";
import Teamdetail from "./components/Myteam/Teamdetail";
import Newleaderprofile from "./components/Myteam/Newleaderprofile";
import Existingleaders from "./components/Myteam/Existingleaders";
import axios from "axios";
import { Api_Url } from "./utils/ApiUrl";
import useEagerConnect from "./hooks/useEagerConnect";
import Forgotpassword from "./components/Forgotpassword/Forgotpassword";
import Emailsent from "./components/Forgotpassword/Emailsent";
import Newpassword from "./components/Forgotpassword/Newpassword";
import Changesuccess from "./components/Forgotpassword/Changesuccess";
import Staking from "./components/Staking/Staking";
import { Modal } from "react-bootstrap";
import Education from "./components/Education/Education";

function App() {
  useEagerConnect();
  const val = localStorage.getItem("accessToken");
  const [token, setToken] = useState(val);
  const [usdPrice, setUsdPrice] = useState("");
  const [tomiPrice, setTomiPrice] = useState("");
  const [btcPrice, setBtcPrice] = useState("");
  const [pepePrice, setPepePrice] = useState("");
  const [linkPrice, setLinkPrice] = useState("");
  const [uniPrice, setUniPrice] = useState("");
  const [dopPrice, setDopPrice] = useState("");
  const [gemsPrice, setGemsPrice] = useState("");

  const [show, setShow] = useState(false);
  const telegramWrapperRef = useRef(null);
  const telegramLink = "https://t.me/+XyX3LjVHvjcyMmM8";
  useEffect(() => {
    if (!tomiPrice) {
      // getTomiPrice();
      axios
        .get("https://min-api.cryptocompare.com/data/price?fsym=TOMI&tsyms=USD")
        .then((res) => {
          setTomiPrice(res?.data?.USD);
          // setEthUsd(res.data);
        });
    }
    if (!usdPrice) {
      axios
        .get("https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD")
        .then((res) => {
          setUsdPrice(res?.data?.USD);
          // setEthUsd(res.data);
        });
    }
    if (!btcPrice) {
      axios
        .get("https://min-api.cryptocompare.com/data/price?fsym=BTC&tsyms=USD")
        .then((res) => {
          setBtcPrice(res?.data?.USD);
          // setEthUsd(res.data);
        });
    }
    if (!pepePrice) {
      axios
        .get("https://min-api.cryptocompare.com/data/price?fsym=PEPE&tsyms=USD")
        .then((res) => {
          setPepePrice(res?.data?.USD);
          // setEthUsd(res.data);
        });
    }
    if (!linkPrice) {
      axios
        .get("https://min-api.cryptocompare.com/data/price?fsym=LINK&tsyms=USD")
        .then((res) => {
          setLinkPrice(res?.data?.USD);
          // setEthUsd(res.data);
        });
    }
    if (!uniPrice) {
      axios
        .get("https://min-api.cryptocompare.com/data/price?fsym=UNI&tsyms=USD")
        .then((res) => {
          setUniPrice(res?.data?.USD);
          // setEthUsd(res.data);
        });
    }

    if (!dopPrice) {
      axios
        .get(
          `https://mainnet-api-prod.dop.org/transactions/get-price?coinId=data-ownership-protocol`
        )
        .then((res) => {
          const data = res?.data?.data?.market_data?.current_price?.usd;

          setDopPrice(data);
          // setEthUsd(res.data);
        });
    }
    if (!gemsPrice) {
      axios
        .get("https://min-api.cryptocompare.com/data/price?fsym=GEMS&tsyms=USD")
        .then((res) => {
          setGemsPrice(res?.data?.USD);
          // setEthUsd(res.data);
        });
    }
  }, []);

  const getTomiPrice = async () => {
    try {
      var config = {
        method: "get",
        url: `${Api_Url}/users/tomi-price`,
      };

      axios(config)
        .then(function (res) {
          setTomiPrice(res?.data?.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (error) {
      console.error("Error fetching data:", error);
      // throw error;
    }
  };

  const [user, setUser] = useState("");

  useEffect(() => {
    const hasSeenPopup = localStorage.getItem("hasSeenTelegramPopup");

    if (!hasSeenPopup && token) {
      setShow(true);
    }
  }, [token]);

  const handleClose = () => {
    setShow(false);
    localStorage.setItem("hasSeenTelegramPopup", "true");
  };

  const [telegrampop, setTelegrampop] = useState(false);

  const searchParams = useMemo(() => {
    if (typeof window !== "undefined") {
      return new URLSearchParams(window.location.search);
    }
    return new URLSearchParams(); // Returns a blank set if not on client side
  }, []);

  useEffect(() => {
    setId(searchParams.get("id"));
  }, [searchParams]);

  const [id, setId] = useState("");
  useEffect(() => {
    const loadTelegramWidget = () => {
      if (telegramWrapperRef.current && token) {
        // Clear previous script content
        telegramWrapperRef.current.innerHTML = "";

        // Create a new script element
        const scriptElement = document.createElement("script");
        scriptElement.src = "https://telegram.org/js/telegram-widget.js?22";
        scriptElement.setAttribute("data-telegram-login", "gems_telegram_bot"); // Correct bot username
        scriptElement.setAttribute("data-size", "large");
        scriptElement.setAttribute("data-auth-url", "https://leaders.gems.vip"); // Correct auth URL
        scriptElement.async = true;

        // Add event listeners for success/failure of script loading
        scriptElement.onload = () =>
          console.log("Telegram widget loaded successfully.");
        scriptElement.onerror = (err) =>
          console.error("Failed to load Telegram widget script:", err);

        // Append the script element to the wrapper ref
        telegramWrapperRef.current.appendChild(scriptElement);
      } else {
        console.log("Either token is missing or wrapper ref is not ready.");
      }
    };

    // Ensure that the ref is available before loading the widget
    if (telegramWrapperRef.current && token) {
      loadTelegramWidget();
    }

    // Optionally add a slight delay to ensure the DOM is ready
    const timeoutId = setTimeout(() => {
      loadTelegramWidget();
    }, 100);

    return () => clearTimeout(timeoutId);
  }, [token]);
  useEffect(() => {
    if (id) {
      toggleTelegram();
    }
  }, [id]);

  const toggleTelegram = async () => {
    const postData = {
      telegramId: id,
    };
    axios
      .post(`${Api_Url}/users/follower-telegram`, postData, {
        headers: {
          Authorization: `Bearer ${val}`, // Include your authentication token here
        },
      })
      .then(async (response) => {
        let headers = {
          Authorization: `Bearer ${val}`, // Include your authentication token here
        };
        axios
          .get(`${Api_Url}/users/profile`, { headers })
          .then((response) => {
            // Handle the success of the GET request
            setUser(response.data?.data);
          })
          .catch((error) => {
            // Handle errors that occurred during the GET request
            console.error("Error:", error);
          });
      })
      .catch((error) => {
        // toast.error(error?.response?.data?.message);
        // router.push("/");
        if (
          error?.response?.data?.message !==
          "The Telegram account you are trying to log in to is affiliated with another DOP account"
        ) {
          // setTelegrampop(true);
        }
        console.error("Error2:", error);
      });
  };

  return (
    <>
      <ToastContainer
        style={{ fontSize: "14px", zIndex: "9999999999999999" }}
        theme="dark"
        position="top-right"
      />
      <img
        src="\assets\maintopshadow.png"
        alt="img"
        className="img-fluid maintopshadow"
      />
      {/* <div
        className={
          user?.follow_Us_On_Telegram?.isCompleted
            ? "telegram-login-widget flex justify-center disableaftercompleted"
            : "telegram-login-widget flex justify-center"
        }
        ref={telegramWrapperRef}
      ></div> */}
      <Router>
        <Switch>
          {/* <Route exact path="/signup" render={() => <Signup />} /> */}
          <Route
            exact
            path="/login"
            render={() => <Login setToken={setToken} />}
          />
          <Route
            exact
            path="/forgotpassword"
            render={() => <Forgotpassword />}
          />
          <Route exact path="/emailsent" render={() => <Emailsent />} />
          <Route exact path="/reset-password" render={() => <Newpassword />} />
          <Route exact path="/changesuccess" render={() => <Changesuccess />} />
          {token && token !== null && token !== undefined ? (
            <>
              <Route exact path="/">
                <Landing />
              </Route>

              <Route
                exact
                path="/dashboard"
                render={() => (
                  <Dashboard
                    tomiPrice={tomiPrice}
                    usdPrice={usdPrice}
                    btcPrice={btcPrice}
                    pepePrice={pepePrice}
                    linkPrice={linkPrice}
                    uniPrice={uniPrice}
                    dopPrice={dopPrice}
                    gemsPrice={gemsPrice}
                  />
                )}
              />
              <Route
                exact
                path="/dashboarddetail"
                render={() => (
                  <Dashboarddetail
                    tomiPrice={tomiPrice}
                    usdPrice={usdPrice}
                    btcPrice={btcPrice}
                    pepePrice={pepePrice}
                    linkPrice={linkPrice}
                    uniPrice={uniPrice}
                    dopPrice={dopPrice}
                    gemsPrice={gemsPrice}
                  />
                )}
              />
              <Route
                exact
                path="/transaction"
                render={() => (
                  <AllTransaction
                    tomiPrice={tomiPrice}
                    usdPrice={usdPrice}
                    btcPrice={btcPrice}
                    pepePrice={pepePrice}
                    linkPrice={linkPrice}
                    uniPrice={uniPrice}
                    dopPrice={dopPrice}
                    gemsPrice={gemsPrice}
                  />
                )}
              />
              <Route exact path="/project" render={() => <Project />} />
              <Route
                exact
                path="/myclaims"
                render={() => (
                  <Myclaims
                    tomiPrice={tomiPrice}
                    usdPrice={usdPrice}
                    btcPrice={btcPrice}
                    pepePrice={pepePrice}
                    linkPrice={linkPrice}
                    uniPrice={uniPrice}
                    dopPrice={dopPrice}
                    gemsPrice={gemsPrice}
                  />
                )}
              />
              <Route
                exact
                path="/leaders"
                render={() => (
                  <Leaders
                    tomiPrice={tomiPrice}
                    usdPrice={usdPrice}
                    btcPrice={btcPrice}
                    pepePrice={pepePrice}
                    linkPrice={linkPrice}
                    uniPrice={uniPrice}
                    dopPrice={dopPrice}
                    gemsPrice={gemsPrice}
                  />
                )}
              />
              <Route
                exact
                path="/viewdetail"
                render={() => (
                  <ViewDetail
                    tomiPrice={tomiPrice}
                    usdPrice={usdPrice}
                    btcPrice={btcPrice}
                    pepePrice={pepePrice}
                    linkPrice={linkPrice}
                    uniPrice={uniPrice}
                    dopPrice={dopPrice}
                    gemsPrice={gemsPrice}
                  />
                )}
              />
              <Route exact path="/myteam" render={() => <Myteam />} />
              <Route exact path="/profile" render={() => <Profile />} />
              <Route exact path="/edit" render={() => <Editprofile />} />
              <Route exact path="/teamdetail" render={() => <Teamdetail />} />
              <Route
                exact
                path="/newleaderprofile"
                render={() => <Newleaderprofile />}
              />
              <Route
                exact
                path="/existingleaders"
                render={() => <Existingleaders />}
              />
              <Route
                exact
                path="/staking"
                render={() => <Staking usdPrice={usdPrice} />}
              />
              <Route exact path="/education" render={() => <Education />} />
            </>
          ) : (
            <>
              <Redirect to="/login" />
            </>
          )}
        </Switch>
      </Router>

      <Modal
        className="walletconnectmodal telegram-modal-popup"
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Join Telegram</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="addedmodal">
            <div className="inside-content">
              <img
                src="\assets\telegram-popup-bg.png"
                alt="addedimg"
                className="addedimg telegram-popup-bg"
              />
              <img
                src="\assets\telegram-icon.svg"
                alt="addedimg"
                className="addedimg edbyefybyef"
              />
              <h6 className="addedhead">Join Our</h6>
              <h5>Gems Leaders Updates Channel</h5>
            </div>

            {!id ? (
              <>
                {/* <div className="newbutton">
              <div className="mywidth" ref={telegramWrapperRef}></div>
            </div> */}
                <div className="newbutton">
                  <div className="mywidth" ref={telegramWrapperRef}>
                    Login Telegram
                  </div>
                  {/* <button className="whitenewbtn"></button> */}
                </div>
              </>
            ) : (
              <a
                href={telegramLink}
                target="_blank"
                className="greybtn w-100"
                rel="noopener noreferrer"
                onClick={() => {
                  handleClose();
                }}
              >
                Join Now
              </a>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default App;

// import React, { useState, useEffect } from "react";
// import Header from "../landing/navbar/Header";
// import Footerline from "../landing/footer/Footerline";
// import { Link, useHistory } from "react-router-dom";

// import { Api_Url } from "../../utils/ApiUrl";
// import axios from "axios";
// import { toast, ToastContainer } from "react-toastify";
// const Login = ({ setToken }) => {
//   var token = localStorage.getItem("accessToken");

//   const [userName, setUserName] = useState(null);
//   const [password, setPassword] = useState(null);
//   const [showPassword, setShowPassword] = useState(false);
//   const [showError, setError] = useState("");
//   const [loader, setLoader] = useState(false);
//   const [errors, setErrors] = useState({});
//   const history = useHistory();

//   const userLogin = async () => {
//     const lowerCaseEmail = userName.toLowerCase();
//     localStorage.removeItem("indexvalue");
//     if (ValidateForm()) {
//       const data = {
//         email: lowerCaseEmail,
//         password: password,
//         rememberMe: false,
//       };
//       setLoader(true);
//       await axios
//         .post(`${Api_Url}/auth/users/signin`, data)
//         .then((res) => {
//           const resData = res?.data?.data;
//           setToken(resData?.accessToken);
//           localStorage.setItem("userId", JSON.stringify(resData?.user));
//           localStorage?.setItem("accessToken", resData?.accessToken);
//           localStorage?.setItem("refreshToken", resData?.refreshToken);
//           localStorage?.setItem("email", resData?.admin?.email);
//           localStorage?.setItem("id", resData?.admin?._id);
//           localStorage?.setItem("user", JSON.stringify(resData?.user));
//           setLoader(false);
//           history.push("/dashboard");
//         })
//         .catch((err) => {
//           if (
//             err?.response?.data?.statusCode == 403
//             // &&
//             // err?.response?.data?.message == "You are blocked"
//           ) {
//           } else {
//             toast.error(err?.response?.data.message, {
//               position: "top-right",
//               autoClose: 2000,
//             });
//           }
//           console.log("err response: ", err?.response);
//           setLoader(false);
//           // setError(err?.response?.data?.message);
//         });
//     }
//   };

//   const ValidateForm = () => {
//     // let input = userInput;
//     let errors = {};
//     let isValid = true;

//     if (!userName) {
//       isValid = false;
//       errors["username"] = "*Please enter a username";
//     }

//     if (!password) {
//       isValid = false;
//       errors["password"] = "*Please enter a password";
//     }

//     setErrors(errors);
//     return isValid;
//   };
//   // useEffect(() => {
//   //   if (token) {
//   //     history.push("/");
//   //   }
//   // }, []);
//   return (
//     <>
//       <Header />
//       <section className="mainsignup mainlogin">
//         <div className="theme-container">
//           <div className="mainsignupdiv">
//             <div className="innersignup">
//               <h6 className="signuphead">log into your Leader account</h6>
//               <div className="material-textfield ">
//                 <input
//                   type="text"
//                   name="email"
//                   placeholder="Email Address"
//                   value={userName}
//                   onChange={(e) => {
//                     setUserName(e?.target?.value);
//                     let tempErrors = errors;
//                     tempErrors["username"] = "";
//                     setErrors(errors);
//                   }}
//                 />
//                 <label>Email Address</label>
//               </div>
//               <div className="material-textfield ">
//                 <input
//                   name="email"
//                   placeholder="Password"
//                   type={showPassword ? "text" : "password"}
//                   value={password}
//                   onChange={(e) => {
//                     setPassword(e?.target?.value);
//                     let tempErrors = errors;
//                     tempErrors["password"] = "";
//                     setErrors(tempErrors);
//                     setError("");
//                   }}
//                 />
//                 <label>Password</label>
//               </div>
//               <div className="lowerlogin">
//                 {/* <div className="checkboxmain">
//                   <label class="checkbox" for="yellow">
//                     <input
//                       type="checkbox"
//                       name="color"
//                       value="yellow"
//                       id="yellow"
//                     />
//                     <span class="checkmark"></span>
//                   </label>
//                   <p className="checkboxpara">Keep me logged in</p>
//                 </div> */}

//                 <p className="forgotpara">Forgot Password?</p>
//               </div>
//               {/* <Link to="/dashboard" className="w-100"> */}
//               <button
//                 className="greybtn w-100 gapbtn"
//                 onClick={() => userLogin()}
//               >
//                 Log In{" "}
//                 <img
//                   src="\assets\signupassets\arrowbtnimg.svg"
//                   alt="arrowimg"
//                   className="arrowimg"
//                 />
//               </button>
//               {/* </Link> */}
//             </div>
//           </div>
//         </div>
//       </section>
//       <Footerline />
//     </>
//   );
// };

// export default Login;
import React, { useState } from "react";
import Header from "../landing/navbar/Header";
import Footerline from "../landing/footer/Footerline";
import { Link, useHistory } from "react-router-dom";
import { Api_Url } from "../../utils/ApiUrl";
import axios from "axios";
import { toast } from "react-toastify";

const Login = ({ setToken }) => {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [errors, setErrors] = useState({});
  const [loader, setLoader] = useState(false);
  const history = useHistory();

  const userLogin = async () => {
    const lowerCaseEmail = userName.toLowerCase();
    const isValid = ValidateForm();

    if (isValid) {
      const data = {
        email: lowerCaseEmail,
        password: password,
        rememberMe: false,
      };
      setLoader(true);
      try {
        const res = await axios.post(`${Api_Url}/auth/users/signin`, data);
        const resData = res?.data?.data;
        setToken(resData?.accessToken);
        localStorage.setItem("userId", JSON.stringify(resData?.user));
        localStorage?.setItem("accessToken", resData?.accessToken);
        localStorage?.setItem("refreshToken", resData?.refreshToken);
        localStorage?.setItem("email", resData?.admin?.email);
        localStorage?.setItem("id", resData?.admin?._id);
        localStorage?.setItem("user", JSON.stringify(resData?.user));
        setLoader(false);
        history.push("/dashboard");
        toast.success(resData.data.message);
      } catch (err) {
        if (err?.response?.data?.statusCode === 403) {
          // Handle blocked user
        } else {
          toast.error(err?.response?.data.message, {
            position: "top-right",
            autoClose: 2000,
          });
        }
        console.log("err response: ", err?.response);
        setLoader(false);
      }
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      userLogin();
    }
  };
  const ValidateForm = () => {
    let errors = {};
    let isValid = true;

    if (!userName.trim()) {
      isValid = false;
      errors["username"] = "Email is Required";
    }

    if (!password.trim()) {
      isValid = false;
      errors["password"] = "Password is Required";
    }

    setErrors(errors);
    return isValid;
  };

  return (
    <>
      <div className="wrapper-login">
        <Header />
        <section className="mainsignup mainlogin">
          <img
            src="\assets\video-shadow.png"
            alt="img"
            className="img-fluid video-shadow"
          />
          <video
            className="main-banner-video"
            muted="muted"
            playsinline="playsinline"
            autoPlay
            loop
            width="100%"
            id="myVideo"
          >
            <source src="\login-bg.mp4" type="video/mp4" />
          </video>
          <div className="theme-container">
            <div className="mainsignupdiv">
              <div className="innersignup">
                <img
                  src="\logo-green.svg"
                  alt="img"
                  className="img-fluid logo-green"
                />
                <h6 className="signuphead">log into your Leader account</h6>
                <div className="material-textfield ">
                  <input
                    type="text"
                    name="email"
                    placeholder="Email Address"
                    onKeyDown={handleKeyDown}
                    value={userName}
                    onChange={(e) => {
                      setUserName(e.target.value);
                      setErrors({ ...errors, username: "" });
                    }}
                  />
                </div>
                {errors.username != "" && (
                  <div className="error material-textfield text-danger">
                    <p style={{ fontSize: 14 }}>{errors.username}</p>
                  </div>
                )}
                <div className="material-textfield ">
                  <input
                    name="email"
                    onKeyDown={handleKeyDown}
                    placeholder="Password"
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                      setErrors({ ...errors, password: "" });
                    }}
                  />
                  <img
                    onClick={() => setShowPassword(!showPassword)}
                    style={{ cursor: "pointer" }}
                    src="\eye.svg"
                    alt="img"
                    className="img-fluid eyeimg"
                  />
                </div>
                {errors.password != "" && (
                  <div className="error text-danger material-textfield ">
                    <p style={{ fontSize: 14 }}>{errors.password}</p>
                  </div>
                )}

                <div className="custom-checkbox">
                  <div class="form-group">
                    <input type="checkbox" id="html" />
                    <label for="html">Keep me logged in</label>
                  </div>
                  <Link to="/forgotpassword">Forgot Password?</Link>
                </div>
                <button
                  className="greybtn w-100 gapbtn"
                  onClick={userLogin}
                  disabled={loader}
                >
                  {loader ? <span>Loading...</span> : <>Log In</>}
                </button>
              </div>
            </div>
          </div>
        </section>
        <Footerline />
      </div>
    </>
  );
};

export default Login;

import React, { useState } from "react";
import "./profile.scss";
import Header from "../landing/navbar/Header";
import { Link } from "react-router-dom";
import Footerline from "../landing/footer/Footerline";
import { Api_Url } from "../../utils/ApiUrl";
import axios from "axios";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

const Editprofile = () => {
  const storedProfileData = localStorage.getItem("profileData");
  const parsedProfileData = JSON.parse(storedProfileData) || {};
  const [name, setName] = useState(parsedProfileData?.name || "");
  const [isCopied, setIsCopied] = useState(false);
  const [email, setEmail] = useState(parsedProfileData?.email || "");
  const [picture, setPicture] = useState(parsedProfileData?.imgUrl || "");
  const [selectedFile, setSelectedFile] = useState(null);
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");

  const [bio, setBio] = useState("");

  const token = localStorage.getItem("accessToken");
  const history = useHistory();
  const handleNameChange = (e) => {
    setName(e.target.value);
    setNameError("");
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setEmailError("");
  };
  const handleBioChange = (e) => {
    setBio(e.target.value);
  };

  const EditProfile = async (e) => {
    e.preventDefault();
    if (selectedFile) {
      handleUpload();
    }
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    setNameError("");
    setEmailError("");

    if (name.trim() === "") {
      setNameError("Name is required");
    }

    if (email.trim() === "") {
      setEmailError("Email is required");
    } else if (!emailPattern.test(email)) {
      setEmailError("Invalid email format");
    }

    if (!nameError && !emailError) {
      try {
        const config = {
          method: "patch",
          url: `${Api_Url}/users/leaders-profile`,
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          data: {
            name: name,
            email: email,
            bio: bio,
          },
        };
        const response = await axios(config);
        if (response.status === 200) {
          localStorage.setItem("name", name);
          localStorage.setItem("email", email);
          const user = JSON.parse(localStorage?.getItem("user"));
          user.name = name;
          user.email = email;
          localStorage.setItem("user", JSON.stringify(user));
          setName("");
          setEmail("");
          setBio("");
          toast.success("Profile updated successfully");
          setTimeout(function () {
            history.push("/profile");
          }, 3000);
        } else {
          toast.error("Error updating profile: " + response.status);
        }
      } catch (error) {
        toast.error("An error occurred: " + error.message);
      }
    }
  };

  const handleFileChange = (e) => {
    // Get the selected file from the input field
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target.result;

      img.onload = () => {
        const MAX_WIDTH = 800;
        const MAX_HEIGHT = 600;
        let width = img.width;
        let height = img.height;

        if (width > MAX_WIDTH) {
          height *= MAX_WIDTH / width;
          width = MAX_WIDTH;
        }

        if (height > MAX_HEIGHT) {
          width *= MAX_HEIGHT / height;
          height = MAX_HEIGHT;
        }

        const canvas = document.createElement("canvas");
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob((blob) => {
          const resizedImage = new File([blob], file.name, {
            type: "image/jpeg",
          });
          setSelectedFile(resizedImage);
        }, "image/jpeg");
      };
    };

    reader.readAsDataURL(file);
  };

  const handleUpload = async () => {
    try {
      // Create FormData and append the selected file
      const formData = new FormData();
      if (selectedFile) {
        formData.append("userProfileImage", selectedFile);
      }
      // Make the API call
      const response = await axios.patch(
        `${Api_Url}/users/profile-image`,
        formData,
        {
          headers: {
            Authorization: "Bearer " + token,
            // "Content-Type": "multipart/form-data", // Set correct content type for file upload
          },
        }
      );

      if (response.status === 200) {
        // console.log(response);
        toast.success("Profile Image updated successfully");
        const newImageUrl = response?.data?.imgUrl;
        setPicture(newImageUrl);
        const user = JSON.parse(localStorage?.getItem("user"));
        user.imgUrl = URL.createObjectURL(selectedFile);
        localStorage.setItem("user", JSON.stringify(user));
        setTimeout(function () {
          history.push("/profile");
        }, 3000);

        // Optionally, you can update the state or refetch the data to reflect the changes
      } else {
        toast.error("Error updating profile: " + response.status);
      }
    } catch (error) {
      toast.error("An error occurred: " + error.message);
    }
  };

  const handleCopyClick = () => {
    const textToCopy = parsedProfileData?.walletAddress;
    const tempTextArea = document.createElement("textarea");
    tempTextArea.value = textToCopy;
    document.body.appendChild(tempTextArea);
    tempTextArea.select();
    document.execCommand("copy");
    document.body.removeChild(tempTextArea);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  return (
    <>
      <Header />
      <>
        <section className="editprofile">
          <div className="theme-container">
            <div className="mainparent">
              <div className="parent">
                <div className="left">
                  <Link to="/profile">
                    <img
                      src="\assets\dashboardassets\Arrow_Left_LG11.svg"
                      alt="img"
                      className="img-fluid"
                    />
                  </Link>
                </div>
                <div className="right">
                  <h3>Edit your profile</h3>
                </div>
              </div>
              <div className="innercard">
                <div className="innercardparent">
                  <div className="left">
                    {selectedFile === null ? (
                      <div className="uploadedimg">
                        <img
                          src={picture}
                          alt="img"
                          className="uploadedinnerimg"
                        />
                      </div>
                    ) : (
                      <div className="uploadedimg">
                        <img
                          src={URL.createObjectURL(selectedFile)}
                          alt="img"
                          className="uploadedinnerimg"
                        />
                      </div>
                    )}
                  </div>
                  <div className="right">
                    <h2>Profile Image</h2>
                    <p>
                      We recommend uploading images in 400x400 resolution. Max 5
                      MB in JPEG or PNG format
                    </p>
                    <input
                      type="file"
                      onChange={handleFileChange}
                      accept="image/*"
                      className="d-none"
                      id="fileInput"
                    />
                    <label
                      for="fileInput"
                      id="uploadLabel"
                      className="uploadbtn"
                    >
                      Upload Image
                    </label>
                    {/* {!selectedFile ? (
                    ) : (
                      <button onClick={handleUpload} className="uploadbtn">
                        Change Image
                      </button>
                    )} */}
                  </div>
                </div>
                <div className="inputparent">
                  <div className="muinput">
                    <div className="material-textfield">
                    <label>Name</label>
                      <input
                        placeholder="Clayton Bowers"
                        type="text"
                        value={name}
                        onChange={handleNameChange}
                      />
                    </div>
                    {nameError && <p className="text-danger">{nameError}</p>}
                  </div>
                  <div className="muinput">
                    <div className="material-textfield">
                    <label>Email</label>
                      <input
                        placeholder="claytonbowers@gmail.com"
                        type="text"
                        value={email}
                        onChange={handleEmailChange}
                      />
                      
                    </div>
                    {emailError && <p className="text-danger">{emailError}</p>}
                  </div>
                </div>
                <div className="inputparent1">
                  <textarea
                    placeholder={
                      parsedProfileData?.bio.length > 0
                        ? "Add your bio here ..."
                        : "Add your bio here ..."
                    }
                    defaultValue={
                      parsedProfileData.bio ? parsedProfileData.bio : ""
                    }
                    onChange={handleBioChange}
                  />
                  {/* <textarea
                    placeholder={
                      parsedProfileData?.bio?.length === 0
                        ? ""
                        : parsedProfileData?.bio
                    }
                    type="text"
                    defaultValue={parsedProfileData?.bio}
                    onChange={handleBioChange}
                  /> */}
                </div>
                <div className="inputparent1">
                  <div className="muinput">
                    <div className="material-textfield">
                    <label className="">Wallet Address</label>
                      <input
                        disabled={true}
                        value={parsedProfileData?.walletAddress}
                        placeholder="Wallet Address"
                        type="text"
                        style={{paddingRight: '60px'}}
                      />
                      
                    </div>
                  </div>
                  {isCopied && <div className="copied-text">Copied!</div>}
                  <p onClick={handleCopyClick} className="blue">
                    copy
                  </p>
                </div>
                <div className="btnnn">
                  <button onClick={(e) => EditProfile(e)}>Save Changes</button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
      <Footerline />
    </>
  );
};

export default Editprofile;

import { useCallback } from "react";
import useWeb3 from "../useWeb3";
import environment from "../../utils/Environment";
import {
  getclaimscontract,
  getclaimsnewcontract,
  getGemsClaimsContract,
  getIncentivClaimsContract,
  getLEIAClaimsContract,
  getOlympusClaimsContract,
  getNFAClaimsContract,
  getAstroNovaClaimsContract,
  getMinersClaimsContract,
} from "../../utils/contractHelpers";
import { getClaimNewLatest } from "../../utils/contractHelpers";
export const Claimable = () => {
  const web3 = useWeb3();
  const tokenAddress = environment.Claims;
  const contract = getclaimscontract(tokenAddress, web3);
  const tokenAddressNew = environment.ClaimsNew;
  const contractNew = getclaimsnewcontract(tokenAddressNew, web3);
  const tokenAddressTomi = environment.claimNewLatest;
  const contractNewLatest = getClaimNewLatest(tokenAddressTomi, web3);
  const gemsAddress = environment.GemsClaim;
  const IncentivAddress = environment.IncentivClaim;
  const LEIAAddress = environment.LEIAClaim;
  const OlympusAddress = environment.OlympusClaim;
  const NFAAddress = environment.NFAClaim;
  const AstroNovaAddress = environment.AstroNovaClaim;
  const MinersAddress = environment.MinersClaim;

  const contractGemsClaim = getGemsClaimsContract(gemsAddress, web3);
  const incentivClaimsContract = getIncentivClaimsContract(
    IncentivAddress,
    web3
  );
  const LEIAClaimsContract = getLEIAClaimsContract(LEIAAddress, web3);
  const OlympusClaimsContract = getOlympusClaimsContract(OlympusAddress, web3);
  const NFAClaimsContract = getNFAClaimsContract(NFAAddress, web3);
  const AstroNovaClaimsContract = getAstroNovaClaimsContract(
    AstroNovaAddress,
    web3
  );
  const MinersClaimsContract = getMinersClaimsContract(MinersAddress, web3);

  const project = localStorage.getItem("project");
  const CheckClaimableHook = useCallback(
    async (walletAddress, round, token) => {
      if (project == "INCENTIV") {
        try {
          const claimAmount = await incentivClaimsContract.methods
            .pendingClaims(walletAddress, round, token)
            .call();
          return claimAmount;
        } catch (error) {
          throw error;
        }
      } else if (project == "NFA") {
        try {
          const claimAmount = await NFAClaimsContract.methods
            .pendingClaims(walletAddress, round, token)
            .call();
          return claimAmount;
        } catch (error) {
          throw error;
        }
      } else if (project == "ASTRANOVA") {
        try {
          const claimAmount = await AstroNovaClaimsContract.methods
            .pendingClaims(walletAddress, round, token)
            .call();
          return claimAmount;
        } catch (error) {
          throw error;
        }
      } else if (project == "NODEMINER") {
        try {
          const claimAmount = await MinersClaimsContract.methods
            .pendingClaims(walletAddress, round, token)
            .call();
          return claimAmount;
        } catch (error) {
          throw error;
        }
      } else if (project == "LEIA") {
        try {
          const claimAmount = await LEIAClaimsContract.methods
            .pendingClaims(walletAddress, round, token)
            .call();
          return claimAmount;
        } catch (error) {
          throw error;
        }
      } else if (project == "OLYMPUS") {
        try {
          const claimAmount = await OlympusClaimsContract.methods
            .pendingClaims(walletAddress, round, token)
            .call();
          return claimAmount;
        } catch (error) {
          throw error;
        }
      } else if (project == "GEMS") {
        try {
          const claimAmount = await contractGemsClaim.methods
            .pendingClaims(walletAddress, round, token)
            .call();
          return claimAmount;
        } catch (error) {
          throw error;
        }
      } else {
        if (parseInt(round) < 3) {
          try {
            const claimAmount = await contract.methods
              .toClaim(walletAddress, round)
              .call();
            return claimAmount;
          } catch (error) {
            throw error;
          }
        } else if (parseInt(round) > 6) {
          try {
            const claimAmount = await contractNewLatest.methods
              .pendingClaims(walletAddress, round, token)
              .call();
            return claimAmount;
          } catch (error) {
            throw error;
          }
        } else {
          try {
            const claimAmount = await contractNew.methods
              .toClaim(walletAddress, round)
              .call();
            return claimAmount;
          } catch (error) {
            throw error;
          }
        }
      }
    },
    [contract]
  );

  return { CheckClaimableHook: CheckClaimableHook };
};

export default Claimable;

import React, { useState, useEffect } from "react";
import "./navbar.scss";
import { Link, useLocation } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Dropdown } from "react-bootstrap";
import { NavLink, useHistory } from "react-router-dom";
import { Api_Url } from "../../../utils/ApiUrl";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Web3 from "web3";
import useAuth from "../../../hooks/useAuth";
import { toast } from "react-toastify";
import { useWeb3React } from "@web3-react/core";
import GetGemsBalance from "../../../hooks/dataFetchers/getGemsBalance";
import GetGemsStaked from "../../../hooks/dataFetchers/getGemsStakedAmount";
import Offcanvas from "react-bootstrap/Offcanvas";
const Header = ({
  setAddLeadBool,
  addLeaderBool,
  openWalletModal,
  setOpenWalletModal,
  addLeaderModal,
  setAddLeaderModal,
}) => {
  const { login, logout } = useAuth();
  const { account } = useWeb3React();
  const val = localStorage.getItem("accessToken");
  const { getGemsStaked } = GetGemsStaked();
  const { GetGemsBal } = GetGemsBalance();

  const user = JSON.parse(localStorage?.getItem("user"));

  const [log, setLog] = useState(false);

  const [show, setShow] = useState(() => {
    if (openWalletModal) {
      return openWalletModal;
    } else {
      return false;
    }
  });

  const handleClose = () => {
    setShow(false);
    if (setOpenWalletModal) {
      setOpenWalletModal(false);
    }
  };
  const handleShow = () => setShow(true);

  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  const [percentageValidation, setPercentageValidation] = useState(false);
  const [reputationPoints, setReputaitonPoints] = useState(0);
  const [stakedAmount, setStakedAmount] = useState(0);
  const [balance, setBalance] = useState(0);
  const [reputationError, setReputaionError] = useState(false);

  const ranges = {
    mini_agent: [0, 5],
    agent: [5, 10],
    super_agent: [10, 15],
    mega_agent: [15, 20],
  };

  const location = useLocation();

  const isHomeOrAuthRoute = [
    "/",
    "/signup",
    "/login",
    "/forgotpassword",
    "/emailsent",
    "/reset-password",
    "/changesuccess",
  ].includes(location.pathname);

  const leaderroute = ["/leaders"].includes(location.pathname);

  const isSignOrAuthRoute = [
    "/signup",
    "/login",
    "/forgotpassword",
    "/emailsent",
    "/reset-password",
    "/changesuccess",
  ].includes(location.pathname);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleDropdownToggle = (isOpen) => {
    setIsDropdownOpen(isOpen);
  };
  const history = useHistory();

  const handleNavLinkClick = (path) => {
    history.push(path);
  };

  useEffect(() => {
    const checkTokenExpiry = () => {
      if (!val) {
        // Token is not present, user is not logged in
        return;
      }

      // Split the JWT into its parts (header, payload, and signature)
      const parts = val?.split(".");
      if (parts.length !== 3) {
        // Invalid JWT format
        return;
      }

      // Decode the payload
      const payload = JSON.parse(atob(parts[1]));

      // Get the expiration time from the payload
      const expirationTime = payload.exp * 1000; // Convert to milliseconds
      const currentTime = Date.now();

      if (expirationTime < currentTime) {
        // Token has expired
        Logout(); // Call the logout function
      }
    };

    // Check token expiry every minute (you can adjust the interval as needed)
    const intervalId = setInterval(checkTokenExpiry, 60000);
    // Clean up the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const ReputationPoints = async () => {
    var config = {
      method: "get",
      url: `${Api_Url}/users/reputation-points`,
      headers: {
        Authorization: "Bearer " + val,
      },
    };
    axios(config)
      .then(function (response) {
        setReputaitonPoints(response?.data?.data?.reputationPoints);
      })
      .catch(function (error) {});
  };

  const Logout = async () => {
    var config = {
      method: "delete",
      url: `${Api_Url}/auth/users/logout`,
      headers: {
        Authorization: "Bearer " + val,
      },
    };
    axios(config)
      .then(function (response) {
        if (response?.data?.statusCode == 200) {
          localStorage?.removeItem("accessToken");
          localStorage?.removeItem("refreshToken");
          localStorage?.removeItem("userName");
          localStorage.removeItem("hasSeenTelegramPopup");

          history.push("/login");
        }
      })
      .catch(function (error) {
        if (error?.response?.status == 401) {
          localStorage.removeItem("accessToken");
          history.push("/login");
        }
      });
  };

  const trustWallet = async () => {
    handleClose();
    // setShow(false);
    // SetOpenWalletModal(false);
    // handleShow()
    if (account) {
      const connectorId = window.localStorage.getItem("connectorId");
      await logout(connectorId);
      localStorage.removeItem("connectorId");
      localStorage.removeItem("flag");
    } else {
      login("walletconnect");
      localStorage.setItem("connectorId", "walletconnect");
      localStorage.setItem("flag", "true");
      setLog(true);
    }
  };

  const connectMetaMask1 = async () => {
    handleClose();

    // setShow(false);
    // SetOpenWalletModal(false);
    if (account) {
      const connectorId = window.localStorage.getItem("connectorId");
      await logout(connectorId);
      localStorage.removeItem("connectorId");
      localStorage.removeItem("flag");
    } else {
      login("injected");
      localStorage.setItem("connectorId", "injected");
      localStorage.setItem("flag", "true");
      setLog(true);
    }
  };

  function convertToTitleCase(input) {
    if (input) {
      let words = input
        .split("_")
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        );

      if (words[0] === "mini" || words[0] === "Mini") {
        return "Ambassador";
      }
      if (
        words[words.length - 1].toLowerCase() === "agent" &&
        (words[0] != "mini" || words[0] != "Mini")
      ) {
        words[words.length - 1] = "Leader";
      }
      if (words[0] === "super" || words[0] === "Super") {
        return "Super Leader";
      } else if (words[0] !== "agent") {
        return words.join(" ");
      } else {
        return "Leader";
      }
    } else {
      return "";
    }
  }

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [show3, setShow3] = useState(false);

  const handleClose3 = () => {
    setRole("");
    setReputaionError(false);
    setError("");
    setShow3(false);
    setAddLeaderModal(false);
  };
  const handleShow3 = () => {
    setShow3(true);
    ReputationPoints();
  };
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [name, setName] = useState("");
  const [role, setRole] = useState(null);
  const [addr, setAddr] = useState("");
  const [email, setEmail] = useState("");
  const [rewardStructure, setRewardStructure] = useState("");
  const [percentage, setPercentage] = useState("");

  const [loader, setLoader] = useState(false);
  const [error, setError] = useState("");

  const AddGodAgent = () => {
    const lowerCaseEmail = email.toLowerCase();

    const val = localStorage.getItem("accessToken");
    let url = null;
    if (user?.role === "admin") {
      url = `${Api_Url}/users/god-agents`;
    }
    if (name?.length > 2) {
      if (isAddressValid(addr)) {
        if (isValidEmail(email)) {
          setLoader(true);
          var config = {
            method: "post",
            url: url,
            data: {
              name: name,
              email: lowerCaseEmail,
              walletAddress: addr,
            },
            headers: {
              Authorization: "Bearer " + val,
            },
          };
          axios(config)
            .then(function (response) {
              setLoader(false);
              setAddLeadBool(!addLeaderBool);
              setName("");
              setAddr("");
              setEmail("");
              handleShow1();
              handleClose();
              handleClose3();
            })
            .catch(function (error) {
              setLoader(false);
              setRole(null);

              toast.error(error?.response?.data?.message);
              if (error?.response?.status == 401) {
                localStorage.removeItem("accessToken");
                history.push("/login");
              }
            });
        } else {
          setError("Incorrect Email!");
        }
      } else {
        setError("Incorrect wallet address!");
      }
    } else {
      setError("Name must be of length 3!");
    }
  };

  const AddAgent = () => {
    // if (
    //   (!percentageValidation && rewardStructure == "fixed") ||
    //   (percentageValidation && rewardStructure == "flex")
    // ) {
    const lowerCaseEmail = email.toLowerCase();

    const val = localStorage.getItem("accessToken");
    let url = null;
    if (role === "mega_agent") {
      url = `${Api_Url}/users/mega-agents`;
    } else if (role === "super_agent") {
      url = `${Api_Url}/users/super-agents`;
    } else if (role === "agent") {
      url = `${Api_Url}/users/agents`;
    } else if (role === "mini_agent") {
      url = `${Api_Url}/users/mini-agents`;
    }
    if (name?.length > 2) {
      if (isAddressValid(addr)) {
        if (isValidEmail(email)) {
          setLoader(true);
          var config = {
            method: "post",
            url: url,
            data: {
              name: name,
              email: lowerCaseEmail,
              walletAddress: addr,
              rewardStructure: "fixed",
              ...(rewardStructure == "flex" && { percentage: percentage }),
            },
            headers: {
              Authorization: "Bearer " + val,
            },
          };
          axios(config)
            .then(function (response) {
              setLoader(false);
              setAddLeadBool(!addLeaderBool);
              setName("");
              setAddr("");
              setEmail("");
              handleShow1();
              handleClose();
              handleClose3();
            })
            .catch(function (error) {
              setLoader(false);
              setRole(null);

              toast.error(error?.response?.data?.message);
              if (error?.response?.status == 401) {
                localStorage.removeItem("accessToken");
                history.push("/login");
              }
            });
        } else {
          setError("Incorrect Email!");
        }
      } else {
        setError("Incorrect wallet address!");
      }
    } else {
      setError("Name must be of length 3!");
    }
    // }
  };

  const [selectedProject, setSelectedProject] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [allProjects] = useState(["TOKN", "TON", "3MAG", "LEDO", "ZILLA"]);

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleDropdownSelect = (project) => {
    setSelectedProject(project);
    setSearchTerm(project); // Update input value with the selected project
  };

  const filteredProjects = allProjects.filter((project) =>
    project.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const [toggle, settoggle] = useState(true);

  function isValidEmail(email) {
    var pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return pattern.test(email);
  }

  function isAddressValid(address) {
    return Web3.utils.isAddress(address);
  }

  function convertToTitleCase(input) {
    if (input) {
      let words = input
        .split("_")
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        );

      if (words[0] === "mini" || words[0] === "Mini") {
        return "Ambassador";
      }
      if (
        words[words.length - 1].toLowerCase() === "agent" &&
        (words[0] != "mini" || words[0] != "Mini")
      ) {
        words[words.length - 1] = "Leader";
      }
      if (words[0] === "super" || words[0] === "Super") {
        return "Super";
      } else if (words[0] !== "agent") {
        return words.join(" ");
      } else {
        return "Leader";
      }
    } else {
      return "";
    }
  }

  const handlePercentageChange = (e) => {
    const value = e.target.value;
    const numericValue = parseFloat(value);
    setPercentage(value);

    // Get the allowed range for the current role
    const [min, max] = ranges[role] || [0, 100];

    // Check if the value is within the allowed range
    if (
      value === "" ||
      (!isNaN(numericValue) && numericValue >= min && numericValue <= max)
    ) {
      setError("");
      setPercentageValidation(true);
    } else {
      setPercentageValidation(false);

      setError(
        `Percentage must be between ${min}% and ${max}% for ${convertToTitleCase(
          role
        )}`
      );
    }
  };

  useEffect(() => {
    if (
      account &&
      account.toLowerCase() == user?.walletAddress?.toLowerCase()
    ) {
      getGemsStakedAmount();
      getGemsBalanceFunc();
    }
  }, [account]);

  const getGemsStakedAmount = async () => {
    const staked = await getGemsStaked(account);
    if (staked) {
      setStakedAmount(staked);
    }
  };
  const getGemsBalanceFunc = async () => {
    const bal = await GetGemsBal(account);
    if (bal !== null || bal !== undefined) {
      setBalance(bal);
    }
  };
  const calculateReputaitonPoints = (role) => {
    const temp = {
      mega_agent: 1000000,
      super_agent: 500000,
      agent: 50000,
      mini_agent: 1000,
    };

    return reputationPoints - temp[role];
  };

  const [showsidebar, setShowsidebar] = useState(false);

  const handleClosesidebar = () => setShowsidebar(false);
  const handleShowsidebar = () => setShowsidebar(true);
  return (
    <>
      <section className="mainnavbar mainnavbardashboard">
        <div className="theme-container">
          {isHomeOrAuthRoute ? (
            <div className="customnavbar">
              <Link to="/dashboard">
                <img src="\logoblack.svg" alt="logoimg" className="logoimg" />
              </Link>
              {isSignOrAuthRoute ? null : (
                <button className="whitebtn">Back to Home</button>
              )}
            </div>
          ) : (
            <div className="customnavbar">
              <Navbar expand="lg">
                <Navbar.Brand href="/dashboard">
                  <img src="\logoblack.svg" alt="logoimg" className="logoimg" />
                </Navbar.Brand>
                {/* <Navbar.Toggle /> */}
                <button className="navbar-toggler">
                  <span
                    className="navbar-toggler-icon"
                    onClick={handleShowsidebar}
                  ></span>
                </button>
                <Navbar.Collapse id="navbarScroll">
                  <Nav>
                    {window.location.pathname == "/dashboard" ||
                    window.location.pathname == "/staking" ||
                    window.location.pathname == "/profile" ? (
                      <>
                        {" "}
                        <NavLink
                          activeClassName="active"
                          to="/dashboard"
                          onClick={() => handleNavLinkClick("/dashboard")}
                        >
                          Home
                        </NavLink>
                        <NavLink
                          activeClassName="active"
                          to="/profile"
                          onClick={() => handleNavLinkClick("/projects")}
                        >
                          My Profile
                        </NavLink>
                        <NavLink
                          activeClassName="active"
                          to="/staking"
                          onClick={() => handleNavLinkClick("/staking")}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "3px",
                            justifyContent: "center",
                          }}
                        >
                          {stakedAmount >= 18000 ? (
                            "My Plan"
                          ) : (
                            <>
                              Go Premium{" "}
                              <svg
                                style={{ marginTop: "-5px" }}
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                              >
                                <path
                                  d="M9.375 15.2084H7.5C6.58333 15.2084 5.83333 15.9584 5.83333 16.875V17.0834H5C4.65833 17.0834 4.375 17.3667 4.375 17.7084C4.375 18.05 4.65833 18.3334 5 18.3334H15C15.3417 18.3334 15.625 18.05 15.625 17.7084C15.625 17.3667 15.3417 17.0834 15 17.0834H14.1667V16.875C14.1667 15.9584 13.4167 15.2084 12.5 15.2084H10.625V13.3C10.4167 13.325 10.2083 13.3334 10 13.3334C9.79167 13.3334 9.58333 13.325 9.375 13.3V15.2084Z"
                                  fill="#EAB44C"
                                />
                                <path
                                  d="M15.4 9.69996C15.95 9.49163 16.4333 9.14996 16.8167 8.76663C17.5917 7.90829 18.1 6.88329 18.1 5.68329C18.1 4.48329 17.1583 3.54163 15.9583 3.54163H15.4917C14.95 2.43329 13.8167 1.66663 12.5 1.66663H7.49999C6.18333 1.66663 5.04999 2.43329 4.50833 3.54163H4.04166C2.84166 3.54163 1.89999 4.48329 1.89999 5.68329C1.89999 6.88329 2.40833 7.90829 3.18333 8.76663C3.56666 9.14996 4.04999 9.49163 4.59999 9.69996C5.46666 11.8333 7.54999 13.3333 10 13.3333C12.45 13.3333 14.5333 11.8333 15.4 9.69996ZM12.3667 7.04163L11.85 7.67496C11.7667 7.76663 11.7083 7.94996 11.7167 8.07496L11.7667 8.89163C11.8 9.39163 11.4417 9.64996 10.975 9.46663L10.2167 9.16663C10.1 9.12496 9.89999 9.12496 9.78333 9.16663L9.02499 9.46663C8.55833 9.64996 8.2 9.39163 8.23333 8.89163L8.28333 8.07496C8.29166 7.94996 8.23333 7.76663 8.14999 7.67496L7.63333 7.04163C7.30833 6.65829 7.45 6.23329 7.93333 6.10829L8.725 5.90829C8.85 5.87496 9 5.75829 9.06666 5.64996L9.50833 4.96663C9.78333 4.54163 10.2167 4.54163 10.4917 4.96663L10.9333 5.64996C11 5.75829 11.15 5.87496 11.275 5.90829L12.0667 6.10829C12.55 6.23329 12.6917 6.65829 12.3667 7.04163Z"
                                  fill="#EAB44C"
                                />
                              </svg>
                            </>
                          )}
                        </NavLink>
                      </>
                    ) : (
                      ""
                    )}
                    {window.location.pathname !== "/dashboard" &&
                    window.location.pathname !== "/staking" &&
                    window.location.pathname !== "/profile" ? (
                      <>
                        {/* <NavLink
                          activeClassName="active"
                          to="/project"
                          onClick={() => handleNavLinkClick("/projects")}
                        >
                          Projects
                        </NavLink> */}
                        <NavLink
                          activeClassName="active"
                          to="/dashboarddetail"
                          onClick={() => handleNavLinkClick("/dashboard")}
                        >
                          Dashboard
                        </NavLink>
                        <NavLink
                          activeClassName="active"
                          to="/transaction"
                          onClick={() => handleNavLinkClick("/projects")}
                        >
                          Transactions
                        </NavLink>

                        {user?.role !== "mini_agent" ? (
                          <NavLink
                            activeClassName="active"
                            to="/leaders"
                            onClick={() => handleNavLinkClick("/leaders")}
                          >
                            Leaders
                          </NavLink>
                        ) : null}
                        <NavLink
                          activeClassName="active"
                          to="/myclaims"
                          onClick={() => handleNavLinkClick("/myclaims")}
                        >
                          My Claims
                        </NavLink>
                        {/* <NavLink
                          activeClassName="active"
                          to="/myteam"
                          onClick={() => handleNavLinkClick("/myteam")}
                        >
                          My Team
                        </NavLink> */}
                        {/* <NavLink activeClassName="active" to="/profile" onClick={() => handleNavLinkClick('/profile')}>Profile</NavLink> */}
                        {/* <NavLink
                          activeClassName="active"
                          to="/profile"
                          onClick={() => handleNavLinkClick("/projects")}
                        >
                          My Profile
                        </NavLink>
                        <NavLink
                          activeClassName="active"
                          to="/staking"
                          onClick={() => handleNavLinkClick("/staking")}
                          style={{ display: "flex", alignItems: "center", gap: "3px" }}
                        >
                          Go Premium <svg style={{ marginTop: "-5px" }} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M9.375 15.2084H7.5C6.58333 15.2084 5.83333 15.9584 5.83333 16.875V17.0834H5C4.65833 17.0834 4.375 17.3667 4.375 17.7084C4.375 18.05 4.65833 18.3334 5 18.3334H15C15.3417 18.3334 15.625 18.05 15.625 17.7084C15.625 17.3667 15.3417 17.0834 15 17.0834H14.1667V16.875C14.1667 15.9584 13.4167 15.2084 12.5 15.2084H10.625V13.3C10.4167 13.325 10.2083 13.3334 10 13.3334C9.79167 13.3334 9.58333 13.325 9.375 13.3V15.2084Z" fill="#EAB44C" />
                            <path d="M15.4 9.69996C15.95 9.49163 16.4333 9.14996 16.8167 8.76663C17.5917 7.90829 18.1 6.88329 18.1 5.68329C18.1 4.48329 17.1583 3.54163 15.9583 3.54163H15.4917C14.95 2.43329 13.8167 1.66663 12.5 1.66663H7.49999C6.18333 1.66663 5.04999 2.43329 4.50833 3.54163H4.04166C2.84166 3.54163 1.89999 4.48329 1.89999 5.68329C1.89999 6.88329 2.40833 7.90829 3.18333 8.76663C3.56666 9.14996 4.04999 9.49163 4.59999 9.69996C5.46666 11.8333 7.54999 13.3333 10 13.3333C12.45 13.3333 14.5333 11.8333 15.4 9.69996ZM12.3667 7.04163L11.85 7.67496C11.7667 7.76663 11.7083 7.94996 11.7167 8.07496L11.7667 8.89163C11.8 9.39163 11.4417 9.64996 10.975 9.46663L10.2167 9.16663C10.1 9.12496 9.89999 9.12496 9.78333 9.16663L9.02499 9.46663C8.55833 9.64996 8.2 9.39163 8.23333 8.89163L8.28333 8.07496C8.29166 7.94996 8.23333 7.76663 8.14999 7.67496L7.63333 7.04163C7.30833 6.65829 7.45 6.23329 7.93333 6.10829L8.725 5.90829C8.85 5.87496 9 5.75829 9.06666 5.64996L9.50833 4.96663C9.78333 4.54163 10.2167 4.54163 10.4917 4.96663L10.9333 5.64996C11 5.75829 11.15 5.87496 11.275 5.90829L12.0667 6.10829C12.55 6.23329 12.6917 6.65829 12.3667 7.04163Z" fill="#EAB44C" />
                          </svg>
                        </NavLink> */}
                      </>
                    ) : null}
                  </Nav>
                  <div className="navbtns">
                    {/* {user?.role !== "mini_agent" && leaderroute ? (
                      <button
                        className="conneccctbbtbn inviteleader-btn"
                        onClick={handleShow2}
                      >
                        Invite Leader
                      </button>
                    ) : null} */}
                    {/* <Link to="/education" className="education-btn">
                      {" "}
                      <img
                        src="\assets\play-btn.svg"
                        alt="img"
                        className="img-fluid"
                      />{" "}
                      Education
                    </Link> */}
                    {!account || account == undefined || account == null ? (
                      <button onClick={handleShow} className="conneccctbbtbn">
                        Connect Wallet
                      </button>
                    ) : (
                      <button
                        onClick={connectMetaMask1}
                        className="conneccctbbtbn"
                      >
                        Disconnect
                      </button>
                    )}

                    <Dropdown
                      show={isDropdownOpen}
                      onToggle={handleDropdownToggle}
                      align="end"
                    >
                      <Dropdown.Toggle id="dropdown-basic">
                        <div className="profileimg">
                          <img
                            src={user?.imgUrl}
                            alt="profileimginner"
                            className="profileimginner"
                          />
                        </div>
                        <div className="profilebtntexts">
                          <h6 className="profilebtnhead">{user?.name}</h6>
                          <p className="profilebtnpara">
                            {convertToTitleCase(user?.role)}
                          </p>
                        </div>
                        <span
                          className={`arrow ${isDropdownOpen ? "open" : ""}`}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="15"
                            height="8"
                            viewBox="0 0 15 8"
                            fill="none"
                          >
                            <path
                              d="M0.534668 0.5L0.534668 3.47056L7.768 7.5L7.768 4.52943L0.534668 0.5Z"
                              fill="black"
                            />
                            <path
                              d="M14.9995 0.500244L14.9995 3.47081L7.76618 7.50024L7.76618 4.52968L14.9995 0.500244Z"
                              fill="black"
                            />
                          </svg>
                        </span>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <div className="dropdownmenuinner">
                          <div className="rankmain">
                            <p className="currrankapara">Current Rank</p>
                            <h6 className="rankmainhead">
                              {convertToTitleCase(user?.role)}
                            </h6>
                            {/* <p className="rankwhitepara">585,879 ROY</p> */}
                          </div>
                          <Link to="/profile">
                            <div className="mainprofiledrop">
                              <img
                                src="\assets\dashboardassets\profiledropimg.svg"
                                alt="profileimg"
                              />
                              <p className="profiledroppara">My Profile</p>
                            </div>
                          </Link>
                          <div className="disconnectdrop" onClick={Logout}>
                            <img
                              src="\assets\dashboardassets\disconnectimg.svg"
                              alt="disconnectimg"
                            />
                            <p className="disconnectdroppara">Disconnect</p>
                          </div>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </Navbar.Collapse>
              </Navbar>
            </div>
          )}
        </div>
      </section>

      <Modal
        className="walletconnectmodal"
        show={show || openWalletModal}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Connect wallet</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="walletmain">
            <div
              className="walletinner metamask-none-mobile"
              onClick={() => {
                connectMetaMask1();
              }}
            >
              <img
                src="\assets\signupassets\metamaskicon.svg"
                alt="walletimg"
                className="walletimg"
              />
              <p className="walletpara">Metamask</p>
            </div>
            <div
              onClick={() => {
                trustWallet();
              }}
              className="walletinner"
            >
              <img
                src="\assets\signupassets\walletconnecticon.svg"
                alt="walletimg"
                className="walletimg"
              />
              <p className="walletpara">WalletConnect</p>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="walletconnectmodal addteammembermodal newaddmodal"
        show={show2}
        onHide={handleClose2}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add team member</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="addingmain">
            <div className="addingcard">
              <img
                src="\assets\myteam\plus.svg"
                alt="addingimg"
                className="addingimg"
              />
              <h6 className="addinghead">Add new Leader</h6>
              {/* <p className="addingpara">
                Qui repellat dolores sed repudiandae laboriosam vel modi dolore
                quo ipsum
              </p> */}
              <button
                onClick={() => {
                  handleShow3();
                  handleClose2();
                }}
                className="greybtn w-100"
              >
                Add new leader
              </button>
            </div>
            {/* <div className="addingcard">
              <img
                src="\assets\myteam\addimg.svg"
                alt="addingimg"
                className="addingimg"
              />
              <h6 className="addinghead">Add existing leader</h6>
              <p className="addingpara">
                Qui repellat dolores sed repudiandae laboriosam vel modi dolore
                quo ipsum
              </p>
              <Link to="/existingleaders" className="w-100">
                <button className="blackbtn w-100">Send Invite</button>
              </Link>
            </div> */}
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="walletconnectmodal addteammembermodal"
        show={show3 || addLeaderModal}
        onHide={handleClose3}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add team member</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {user?.role !== "admin" && (
            <Dropdown className="selectdropdown" autoClose="inside">
              <Dropdown.Toggle variant="success" id="dropdown-autoclose-inside">
                <div className="material-textfield ">
                  <input
                    type="text"
                    name="text"
                    placeholder="Select Role"
                    value={searchTerm}
                    onChange={handleInputChange}
                    autoComplete="off"
                  />
                  <label>
                    {role === null
                      ? "Type of Leader"
                      : convertToTitleCase(role)}
                  </label>
                  <img
                    src="\assets\dashboardassets\arrowdropdown.svg"
                    alt="arrowimg"
                    className="arrowimg"
                  />
                </div>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <div className="dropdownmenumain">
                  {user?.role === "god_agent" && (
                    <>
                      <Dropdown.Item>
                        <div
                          onClick={() => {
                            setRole("mega_agent");
                            setError("");
                          }}
                        >
                          <p className="dropitem">Mega Leader</p>
                        </div>
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <div
                          onClick={() => {
                            setRole("super_agent");
                            setError("");
                          }}
                        >
                          <p className="dropitem">Super Leader</p>
                        </div>
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <div
                          onClick={() => {
                            setRole("agent");
                            setError("");
                          }}
                        >
                          <p className="dropitem">Leader</p>
                        </div>
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <div
                          onClick={() => {
                            setRole("mini_agent");
                            setError("");
                          }}
                        >
                          <p className="dropitem">Ambassador</p>
                        </div>
                      </Dropdown.Item>
                    </>
                  )}
                  {user?.role === "mega_agent" && (
                    <>
                      <Dropdown.Item>
                        <div
                          onClick={() => {
                            setRole("super_agent");
                            setError("");
                          }}
                        >
                          <p className="dropitem">Super Leader</p>
                        </div>
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <div
                          onClick={() => {
                            setRole("agent");
                            setError("");
                          }}
                        >
                          <p className="dropitem">Leader</p>
                        </div>
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <div
                          onClick={() => {
                            setRole("mini_agent");
                            setError("");
                          }}
                        >
                          <p className="dropitem">Ambassador</p>
                        </div>
                      </Dropdown.Item>
                    </>
                  )}
                  {user?.role === "super_agent" && (
                    <>
                      <Dropdown.Item>
                        <div
                          onClick={() => {
                            setRole("agent");
                            setError("");
                          }}
                        >
                          <p className="dropitem">Leader</p>
                        </div>
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <div
                          onClick={() => {
                            setRole("mini_agent");
                            setError("");
                          }}
                        >
                          <p className="dropitem">Ambassador</p>
                        </div>
                      </Dropdown.Item>
                    </>
                  )}
                  {user?.role === "agent" && (
                    <>
                      <Dropdown.Item>
                        <div
                          onClick={() => {
                            setRole("mini_agent");
                            setError("");
                          }}
                        >
                          <p className="dropitem">Ambassador</p>
                        </div>
                      </Dropdown.Item>
                    </>
                  )}
                </div>
              </Dropdown.Menu>
            </Dropdown>
          )}
          <div className="material-textfield ">
            <input
              type="text"
              name="text"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <label>Name</label>
          </div>
          <div className="material-textfield ">
            <input
              type="text"
              name="text"
              placeholder="Email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
            <label>Email</label>
          </div>
          <div className="material-textfield ">
            <input
              type="text"
              name="text"
              placeholder="Wallet address"
              value={addr}
              onChange={(e) => setAddr(e.target.value)}
            />
            <label>Wallet address</label>
          </div>
          <div className="reward-custom-box mb-4">
            <div className="inner-text">
              <h6>My Reputation Points</h6>
              <p>{reputationPoints?.toLocaleString()}</p>
            </div>
            <div className="inner-text">
              <h6>points required to add new member</h6>
              <p>
                {role == "mega_agent"
                  ? "1,000,000"
                  : role == "super_agent"
                  ? "500,000"
                  : role == "agent"
                  ? "50,000"
                  : role == "mini_agent"
                  ? "1000"
                  : "--"}
              </p>
            </div>
            <div className="inner-text inner-text-border">
              <h6>total after adding this member</h6>
              <p>
                {role == "mega_agent"
                  ? parseInt(reputationPoints - 1000000)?.toLocaleString()
                  : role == "super_agent"
                  ? parseInt(reputationPoints - 500000)?.toLocaleString()
                  : role == "agent"
                  ? parseInt(reputationPoints - 50000)?.toLocaleString()
                  : role == "mini_agent"
                  ? parseInt(reputationPoints - 1000)?.toLocaleString()
                  : "--"}
                {/* {reputationPoints
                  ? parseInt(reputationPoints - 200)?.toLocaleString()
                  : 0} */}
              </p>
            </div>
          </div>
          {reputationError ? (
            <div className="insuffient-repo">
              <img
                src="\assets\checkcross.svg"
                alt="img"
                className="img-fluid"
              />
              <div className="text">
                <h6>insufficient Reputation points</h6>
                <p>You need more reputation points to add this leader role</p>
              </div>
            </div>
          ) : null}

          {/* 
          <Dropdown className="selectdropdown">
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              <div className="material-textfield ">
                <input
                  type="text"
                  name="text"
                  placeholder="Select Project"
                  value={searchTerm}
                  onChange={handleInputChange}
                  autoComplete="off"
                />
                <label>Select Project</label>
                <img
                  src="\assets\dashboardassets\arrowdropdown.svg"
                  alt="arrowimg"
                  className="arrowimg"
                />
              </div>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <div className="dropdownmenumain">
                {filteredProjects.map((project, index) => (
                  <p
                    key={index}
                    className="dropitem"
                    onClick={() => handleDropdownSelect(project)}
                  >
                    {project}
                  </p>
                ))}
              </div>
            </Dropdown.Menu>
          </Dropdown> */}

          {/* <Dropdown className="selectdropdown" autoClose="inside">
            <Dropdown.Toggle variant="success" id="dropdown-autoclose-inside">
              <div className="material-textfield ">
                <input
                  type="text"
                  name="rewardStructure"
                  placeholder="Select Role"
                 
                  autoComplete="off"
                />
                <label>
                  {!rewardStructure ? "Select Structure" : rewardStructure}
                </label>
                <img
                  src="\assets\dashboardassets\arrowdropdown.svg"
                  alt="arrowimg"
                  className="arrowimg"
                />
              </div>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <div className="dropdownmenumain">
                <Dropdown.Item>
                  <div onClick={() => setRewardStructure("flex")}>
                    <p className="dropitem">Reward Flex Structure</p>
                  </div>
                </Dropdown.Item>
                <Dropdown.Item>
                  <div onClick={() => setRewardStructure("fixed")}>
                    <p className="dropitem">Reward Fixed Structure</p>
                  </div>
                </Dropdown.Item>
              </div>
            </Dropdown.Menu>
          </Dropdown>

          {rewardStructure == "flex" ? (
            <div className="material-textfield ">
              <input
                type="number"
                name="percentage"
                placeholder="Percentage"
                value={percentage}
                onChange={handlePercentageChange}
                min={ranges[role] ? ranges[role][0] : 0}
                max={ranges[role] ? ranges[role][1] : 100}
                step="any" // Allow decimal inputs
              />
              <label>Percentage</label>
            </div>
          ) : null} */}

          <p
            style={{
              color: "red",
              textAlign: "center",
              marginTop: -15,
              marginBottom: 15,
            }}
          >
            {error}
          </p>
          <div className="modalbtns">
            <button className="whitebtn" onClick={handleClose3}>
              Cancel
            </button>
            <button
              className="greybtn"
              disabled={loader}
              onClick={() => {
                if (user?.role === "admin") {
                  AddGodAgent();
                } else if (role) {
                  if (calculateReputaitonPoints(role) >= 0) {
                    AddAgent();
                  } else {
                    setReputaionError(true);
                  }
                } else {
                  setError("Select a Role");
                }
              }}
            >
              Add
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="walletconnectmodal addteammembermodal"
        show={show1}
        onHide={handleClose1}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add team member</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="addedmodal">
            <img src="/assets/check.svg" alt="addedimg" className="addedimg" />
            <h6 className="addedhead">Team member successfully added</h6>
            <button
              className="greybtn w-100"
              onClick={() => {
                handleClose1();
                history.push("/leaders");
                // settoggle(false);
              }}
            >
              Okay
            </button>
          </div>
        </Modal.Body>
      </Modal>

      {/* new modals teams  */}

      <Modal
        className="walletconnectmodal addteammembermodal premium-modal"
        show={show4}
        onHide={handleClose4}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add team member</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="premium-reward-box">
            <img
              src="\assets\reward-modal-bg.png"
              alt="img"
              className="img-fluid reward-modal-bg"
            />
            <div className="main-heading">
              <p>UPGRADE TO</p>
              <img
                src="\assets\premium-icon.svg"
                alt="img"
                className="img-fluid"
              />
            </div>
            <div className="premium-feature">
              <h5 className="heading">GEMS PREMIUM FEATURES</h5>
              <div className="feature-box">
                <p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                  >
                    <path
                      d="M14.6084 6.71818C14.55 6.70985 14.4917 6.70985 14.4334 6.71818C13.1417 6.67652 12.1167 5.61818 12.1167 4.31818C12.1167 2.99318 13.1917 1.90985 14.525 1.90985C15.85 1.90985 16.9334 2.98485 16.9334 4.31818C16.925 5.61818 15.9 6.67652 14.6084 6.71818Z"
                      fill="#329879"
                    />
                    <path
                      d="M17.3252 12.4931C16.3918 13.1181 15.0835 13.3515 13.8752 13.1931C14.1918 12.5098 14.3585 11.7515 14.3668 10.9515C14.3668 10.1181 14.1835 9.32647 13.8335 8.6348C15.0668 8.46813 16.3752 8.70147 17.3168 9.32647C18.6335 10.1931 18.6335 11.6181 17.3252 12.4931Z"
                      fill="#329879"
                    />
                    <path
                      d="M5.36647 6.71818C5.4248 6.70985 5.48314 6.70985 5.54147 6.71818C6.83314 6.67652 7.85814 5.61818 7.85814 4.31818C7.85814 2.98485 6.78314 1.90985 5.4498 1.90985C4.1248 1.90985 3.0498 2.98485 3.0498 4.31818C3.0498 5.61818 4.0748 6.67652 5.36647 6.71818Z"
                      fill="#329879"
                    />
                    <path
                      d="M5.45843 10.9515C5.45843 11.7598 5.63343 12.5265 5.9501 13.2182C4.7751 13.3432 3.5501 13.0932 2.6501 12.5015C1.33343 11.6265 1.33343 10.2015 2.6501 9.3265C3.54176 8.7265 4.8001 8.48484 5.98343 8.61817C5.64176 9.31817 5.45843 10.1098 5.45843 10.9515Z"
                      fill="#329879"
                    />
                    <path
                      d="M10.1 13.4682C10.0333 13.4598 9.95833 13.4598 9.88333 13.4682C8.35 13.4182 7.125 12.1598 7.125 10.6098C7.13333 9.0265 8.40833 7.74316 10 7.74316C11.5833 7.74316 12.8667 9.0265 12.8667 10.6098C12.8583 12.1598 11.6417 13.4182 10.1 13.4682Z"
                      fill="#329879"
                    />
                    <path
                      d="M7.3915 15.1932C6.13317 16.0348 6.13317 17.4182 7.3915 18.2515C8.82484 19.2098 11.1748 19.2098 12.6082 18.2515C13.8665 17.4098 13.8665 16.0265 12.6082 15.1932C11.1832 14.2348 8.83317 14.2348 7.3915 15.1932Z"
                      fill="#329879"
                    />
                  </svg>{" "}
                  Add unlimited team members
                </p>
                <p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                  >
                    <path
                      d="M9.99984 1.90985C5.39984 1.90985 1.6665 5.64318 1.6665 10.2432C1.6665 14.8432 5.39984 18.5765 9.99984 18.5765C14.5998 18.5765 18.3332 14.8432 18.3332 10.2432C18.3332 5.64318 14.5998 1.90985 9.99984 1.90985ZM7.49984 6.90985C7.95817 6.90985 8.33317 7.28485 8.33317 7.74318C8.33317 8.20152 7.9665 8.57652 7.49984 8.57652C7.0415 8.57652 6.6665 8.20152 6.6665 7.74318C6.6665 7.28485 7.0415 6.90985 7.49984 6.90985ZM7.9415 13.1849C7.8165 13.3099 7.65817 13.3682 7.49984 13.3682C7.3415 13.3682 7.18317 13.3099 7.05817 13.1849C6.8165 12.9432 6.8165 12.5432 7.05817 12.3015L12.0582 7.30152C12.2998 7.05985 12.6998 7.05985 12.9415 7.30152C13.1832 7.54318 13.1832 7.94318 12.9415 8.18485L7.9415 13.1849ZM12.4998 13.5765C12.0332 13.5765 11.6582 13.2015 11.6582 12.7432C11.6582 12.2849 12.0332 11.9099 12.4915 11.9099C12.9498 11.9099 13.3248 12.2849 13.3248 12.7432C13.3248 13.2015 12.9582 13.5765 12.4998 13.5765Z"
                      fill="#329879"
                    />
                  </svg>{" "}
                  Earn more commissions
                </p>
                <p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                  >
                    <path
                      d="M17.7498 9.27651C18.0748 9.27651 18.3332 9.01817 18.3332 8.69317V7.91817C18.3332 4.50151 17.2915 3.45984 13.8748 3.45984H6.12484C2.70817 3.45984 1.6665 4.50151 1.6665 7.91817V8.30984C1.6665 8.63484 1.92484 8.89317 2.24984 8.89317C2.99984 8.89317 3.60817 9.50151 3.60817 10.2515C3.60817 11.0015 2.99984 11.6015 2.24984 11.6015C1.92484 11.6015 1.6665 11.8598 1.6665 12.1848V12.5765C1.6665 15.9932 2.70817 17.0348 6.12484 17.0348H13.8748C17.2915 17.0348 18.3332 15.9932 18.3332 12.5765C18.3332 12.2515 18.0748 11.9932 17.7498 11.9932C16.9998 11.9932 16.3915 11.3848 16.3915 10.6348C16.3915 9.88484 16.9998 9.27651 17.7498 9.27651ZM7.49984 7.64317C7.95817 7.64317 8.33317 8.01817 8.33317 8.47651C8.33317 8.93484 7.9665 9.30984 7.49984 9.30984C7.0415 9.30984 6.6665 8.93484 6.6665 8.47651C6.6665 8.01817 7.03317 7.64317 7.49984 7.64317ZM12.4998 13.4765C12.0332 13.4765 11.6582 13.1015 11.6582 12.6432C11.6582 12.1848 12.0332 11.8098 12.4915 11.8098C12.9498 11.8098 13.3248 12.1848 13.3248 12.6432C13.3248 13.1015 12.9665 13.4765 12.4998 13.4765ZM13.2498 8.14317L7.6415 13.7515C7.5165 13.8765 7.35817 13.9348 7.19984 13.9348C7.0415 13.9348 6.88317 13.8765 6.75817 13.7515C6.5165 13.5098 6.5165 13.1098 6.75817 12.8682L12.3665 7.25984C12.6082 7.01817 13.0082 7.01817 13.2498 7.25984C13.4915 7.50151 13.4915 7.90151 13.2498 8.14317Z"
                      fill="#329879"
                    />
                  </svg>{" "}
                  Choose between fixed or flexible commissions
                </p>
                <p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                  >
                    <path
                      d="M15 1.90979H5C3.61667 1.90979 2.5 3.01812 2.5 4.38479V13.4765C2.5 14.8431 3.61667 15.9515 5 15.9515H5.63333C6.3 15.9515 6.93333 16.2098 7.4 16.6765L8.825 18.0848C9.475 18.7265 10.5333 18.7265 11.1833 18.0848L12.6083 16.6765C13.075 16.2098 13.7167 15.9515 14.375 15.9515H15C16.3833 15.9515 17.5 14.8431 17.5 13.4765V4.38479C17.5 3.01812 16.3833 1.90979 15 1.90979ZM8.65 11.0848C8.99167 11.0848 9.275 11.3681 9.275 11.7098C9.275 12.0515 8.99167 12.3348 8.65 12.3348H6.41667C6.05 12.3348 5.70833 12.1598 5.49167 11.8598C5.28333 11.5765 5.23333 11.2265 5.33333 10.8931C5.625 10.0015 6.34167 9.51812 6.975 9.08479C7.64167 8.63479 8.01667 8.35146 8.01667 7.86812C8.01667 7.43479 7.66667 7.08479 7.23333 7.08479C6.8 7.08479 6.45833 7.44312 6.45833 7.87646C6.45833 8.21812 6.175 8.50146 5.83333 8.50146C5.49167 8.50146 5.20833 8.21812 5.20833 7.87646C5.20833 6.75979 6.11667 5.84312 7.24167 5.84312C8.36667 5.84312 9.275 6.75146 9.275 7.87646C9.275 9.05146 8.39167 9.65146 7.68333 10.1348C7.24167 10.4348 6.825 10.7181 6.61667 11.0931H8.65V11.0848ZM14.1667 11.1431H13.9917V11.7181C13.9917 12.0598 13.7083 12.3431 13.3667 12.3431C13.025 12.3431 12.7417 12.0598 12.7417 11.7181V11.1431H11.1083C11.1083 11.1431 11.1083 11.1431 11.1 11.1431C10.6917 11.1431 10.3167 10.9265 10.1083 10.5765C9.9 10.2181 9.9 9.77646 10.1083 9.42646C10.675 8.45146 11.3333 7.34312 11.9333 6.37646C12.2 5.95146 12.7083 5.75979 13.1833 5.89312C13.6583 6.03479 13.9917 6.46812 13.9833 6.96812V9.90146H14.1667C14.5083 9.90146 14.7917 10.1848 14.7917 10.5265C14.7917 10.8681 14.5083 11.1431 14.1667 11.1431Z"
                      fill="#329879"
                    />
                    <path
                      d="M12.7415 9.89311V7.44312C12.2499 8.24312 11.7415 9.10145 11.2832 9.88478H12.7415V9.89311Z"
                      fill="#329879"
                    />
                  </svg>{" "}
                  24/7 Priority support
                </p>
              </div>
            </div>
            <div className="premium-requirement">
              <h5 className="heading">GEMS PREMIUM requirements</h5>
              <div className="points-box">
                <div className="inside-text">
                  <p>GEMS Staking</p>
                  <h6 className="special-text">18,000 GEMS</h6>
                </div>
                <div className="inside-text">
                  <p>your GEMS Staked</p>
                  <h6>{stakedAmount?.toLocaleString()} GEMS</h6>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="modalbtns">
            <button className="whitebtn" onClick={handleClose4}>
              Cancel
            </button>
          </div> */}
        </Modal.Body>
      </Modal>

      <Offcanvas
        show={showsidebar}
        onHide={handleClosesidebar}
        className="mobile-menu"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <img src="/logoblack.svg" alt="logoimg" className="logoimg" />
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Nav>
            {window.location.pathname == "/dashboard" ||
            window.location.pathname == "/staking" ||
            window.location.pathname == "/profile" ? (
              <>
                {" "}
                <NavLink
                  activeClassName="active"
                  to="/dashboard"
                  onClick={() => handleNavLinkClick("/dashboard")}
                >
                  Home
                </NavLink>
                <NavLink
                  activeClassName="active"
                  to="/profile"
                  onClick={() => handleNavLinkClick("/projects")}
                >
                  My Profile
                </NavLink>
                <NavLink
                  activeClassName="active"
                  to="/staking"
                  onClick={() => handleNavLinkClick("/staking")}
                >
                  {stakedAmount >= 18000 ? (
                    "My Plan"
                  ) : (
                    <>
                      Go Premium{" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M9.375 15.2084H7.5C6.58333 15.2084 5.83333 15.9584 5.83333 16.875V17.0834H5C4.65833 17.0834 4.375 17.3667 4.375 17.7084C4.375 18.05 4.65833 18.3334 5 18.3334H15C15.3417 18.3334 15.625 18.05 15.625 17.7084C15.625 17.3667 15.3417 17.0834 15 17.0834H14.1667V16.875C14.1667 15.9584 13.4167 15.2084 12.5 15.2084H10.625V13.3C10.4167 13.325 10.2083 13.3334 10 13.3334C9.79167 13.3334 9.58333 13.325 9.375 13.3V15.2084Z"
                          fill="#EAB44C"
                        />
                        <path
                          d="M15.3999 9.70008C15.9499 9.49175 16.4332 9.15008 16.8166 8.76675C17.5916 7.90842 18.0999 6.88342 18.0999 5.68342C18.0999 4.48342 17.1582 3.54175 15.9582 3.54175H15.4916C14.9499 2.43341 13.8166 1.66675 12.4999 1.66675H7.4999C6.18324 1.66675 5.0499 2.43341 4.50824 3.54175H4.04157C2.84157 3.54175 1.8999 4.48342 1.8999 5.68342C1.8999 6.88342 2.40824 7.90842 3.18324 8.76675C3.56657 9.15008 4.0499 9.49175 4.5999 9.70008C5.46657 11.8334 7.5499 13.3334 9.9999 13.3334C12.4499 13.3334 14.5332 11.8334 15.3999 9.70008ZM12.3666 7.04175L11.8499 7.67508C11.7666 7.76675 11.7082 7.95008 11.7166 8.07508L11.7666 8.89175C11.7999 9.39175 11.4416 9.65008 10.9749 9.46675L10.2166 9.16675C10.0999 9.12508 9.8999 9.12508 9.78324 9.16675L9.0249 9.46675C8.55824 9.65008 8.1999 9.39175 8.23324 8.89175L8.28324 8.07508C8.29157 7.95008 8.23324 7.76675 8.1499 7.67508L7.63324 7.04175C7.30824 6.65842 7.4499 6.23342 7.93324 6.10842L8.7249 5.90842C8.8499 5.87508 8.9999 5.75842 9.06657 5.65008L9.50824 4.96675C9.78324 4.54175 10.2166 4.54175 10.4916 4.96675L10.9332 5.65008C10.9999 5.75842 11.1499 5.87508 11.2749 5.90842L12.0666 6.10842C12.5499 6.23342 12.6916 6.65842 12.3666 7.04175Z"
                          fill="#EAB44C"
                        />
                      </svg>
                    </>
                  )}
                </NavLink>
              </>
            ) : (
              ""
            )}
            {window.location.pathname !== "/dashboard" &&
            window.location.pathname !== "/staking" &&
            window.location.pathname !== "/profile" ? (
              <>
                {/* <NavLink
                          activeClassName="active"
                          to="/project"
                          onClick={() => handleNavLinkClick("/projects")}
                        >
                          Projects
                        </NavLink> */}
                <NavLink
                  activeClassName="active"
                  to="/dashboarddetail"
                  onClick={() => handleNavLinkClick("/dashboard")}
                >
                  Dashboard
                </NavLink>
                <NavLink
                  activeClassName="active"
                  to="/transaction"
                  onClick={() => handleNavLinkClick("/projects")}
                >
                  Transactions
                </NavLink>

                {user?.role !== "mini_agent" ? (
                  <NavLink
                    activeClassName="active"
                    to="/leaders"
                    onClick={() => handleNavLinkClick("/leaders")}
                  >
                    Leaders
                  </NavLink>
                ) : null}
                <NavLink
                  activeClassName="active"
                  to="/myclaims"
                  onClick={() => handleNavLinkClick("/myclaims")}
                >
                  My Claims
                </NavLink>
                {/* <NavLink
                          activeClassName="active"
                          to="/myteam"
                          onClick={() => handleNavLinkClick("/myteam")}
                        >
                          My Team
                        </NavLink> */}
                {/* <NavLink activeClassName="active" to="/profile" onClick={() => handleNavLinkClick('/profile')}>Profile</NavLink> */}
                {/* <NavLink
                          activeClassName="active"
                          to="/profile"
                          onClick={() => handleNavLinkClick("/projects")}
                        >
                          My Profile
                        </NavLink>
                        <NavLink
                          activeClassName="active"
                          to="/staking"
                          onClick={() => handleNavLinkClick("/staking")}
                          style={{ display: "flex", alignItems: "center", gap: "3px" }}
                        >
                          Go Premium <svg style={{ marginTop: "-5px" }} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M9.375 15.2084H7.5C6.58333 15.2084 5.83333 15.9584 5.83333 16.875V17.0834H5C4.65833 17.0834 4.375 17.3667 4.375 17.7084C4.375 18.05 4.65833 18.3334 5 18.3334H15C15.3417 18.3334 15.625 18.05 15.625 17.7084C15.625 17.3667 15.3417 17.0834 15 17.0834H14.1667V16.875C14.1667 15.9584 13.4167 15.2084 12.5 15.2084H10.625V13.3C10.4167 13.325 10.2083 13.3334 10 13.3334C9.79167 13.3334 9.58333 13.325 9.375 13.3V15.2084Z" fill="#EAB44C" />
                            <path d="M15.4 9.69996C15.95 9.49163 16.4333 9.14996 16.8167 8.76663C17.5917 7.90829 18.1 6.88329 18.1 5.68329C18.1 4.48329 17.1583 3.54163 15.9583 3.54163H15.4917C14.95 2.43329 13.8167 1.66663 12.5 1.66663H7.49999C6.18333 1.66663 5.04999 2.43329 4.50833 3.54163H4.04166C2.84166 3.54163 1.89999 4.48329 1.89999 5.68329C1.89999 6.88329 2.40833 7.90829 3.18333 8.76663C3.56666 9.14996 4.04999 9.49163 4.59999 9.69996C5.46666 11.8333 7.54999 13.3333 10 13.3333C12.45 13.3333 14.5333 11.8333 15.4 9.69996ZM12.3667 7.04163L11.85 7.67496C11.7667 7.76663 11.7083 7.94996 11.7167 8.07496L11.7667 8.89163C11.8 9.39163 11.4417 9.64996 10.975 9.46663L10.2167 9.16663C10.1 9.12496 9.89999 9.12496 9.78333 9.16663L9.02499 9.46663C8.55833 9.64996 8.2 9.39163 8.23333 8.89163L8.28333 8.07496C8.29166 7.94996 8.23333 7.76663 8.14999 7.67496L7.63333 7.04163C7.30833 6.65829 7.45 6.23329 7.93333 6.10829L8.725 5.90829C8.85 5.87496 9 5.75829 9.06666 5.64996L9.50833 4.96663C9.78333 4.54163 10.2167 4.54163 10.4917 4.96663L10.9333 5.64996C11 5.75829 11.15 5.87496 11.275 5.90829L12.0667 6.10829C12.55 6.23329 12.6917 6.65829 12.3667 7.04163Z" fill="#EAB44C" />
                          </svg>
                        </NavLink> */}
              </>
            ) : null}
          </Nav>
          <div className="navbtns">
            {/* {user?.role !== "mini_agent" && leaderroute ? (
                      <button
                        className="conneccctbbtbn inviteleader-btn"
                        onClick={handleShow2}
                      >
                        Invite Leader
                      </button>
                    ) : null} */}
            {/* <Link to="/education" className="education-btn">
                      {" "}
                      <img
                        src="\assets\play-btn.svg"
                        alt="img"
                        className="img-fluid"
                      />{" "}
                      Education
                    </Link> */}
            {!account || account == undefined || account == null ? (
              <button onClick={handleShow} className="conneccctbbtbn">
                Connect Wallet
              </button>
            ) : (
              <button onClick={connectMetaMask1} className="conneccctbbtbn">
                Disconnect
              </button>
            )}

            <Dropdown
              show={isDropdownOpen}
              onToggle={handleDropdownToggle}
              align="end"
            >
              <Dropdown.Toggle id="dropdown-basic">
                <div className="profileimg">
                  <img
                    src={user?.imgUrl}
                    alt="profileimginner"
                    className="profileimginner"
                  />
                </div>
                <div className="profilebtntexts">
                  <h6 className="profilebtnhead">{user?.name}</h6>
                  <p className="profilebtnpara">
                    {convertToTitleCase(user?.role)}
                  </p>
                </div>
                <span className={`arrow ${isDropdownOpen ? "open" : ""}`}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="8"
                    viewBox="0 0 15 8"
                    fill="none"
                  >
                    <path
                      d="M0.534668 0.5L0.534668 3.47056L7.768 7.5L7.768 4.52943L0.534668 0.5Z"
                      fill="black"
                    />
                    <path
                      d="M14.9995 0.500244L14.9995 3.47081L7.76618 7.50024L7.76618 4.52968L14.9995 0.500244Z"
                      fill="black"
                    />
                  </svg>
                </span>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <div className="dropdownmenuinner">
                  <div className="rankmain">
                    <p className="currrankapara">Current Rank</p>
                    <h6 className="rankmainhead">
                      {convertToTitleCase(user?.role)}
                    </h6>
                    {/* <p className="rankwhitepara">585,879 ROY</p> */}
                  </div>
                  <Link to="/profile">
                    <div className="mainprofiledrop">
                      <img
                        src="\assets\dashboardassets\profiledropimg.svg"
                        alt="profileimg"
                      />
                      <p className="profiledroppara">My Profile</p>
                    </div>
                  </Link>
                  <div className="disconnectdrop" onClick={Logout}>
                    <img
                      src="\assets\dashboardassets\disconnectimg.svg"
                      alt="disconnectimg"
                    />
                    <p className="disconnectdroppara">Disconnect</p>
                  </div>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default Header;
